import styled from 'styled-components/macro';

const imageHeight = ({ viewport }) => Math.ceil((viewport.width / 21) * 9);
const imageMargin = ({ viewport, scrollTop }) => {
  const height = imageHeight({ viewport });
  if (scrollTop >= height) return -height;
  return 0;
};
export const ImageWrapper = styled.div`
  width: 100%;
  height: ${imageHeight}px;
  background-color: rgba(255, 255, 255, .4);
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-shrink: 0;
  margin-top: ${imageMargin}px;
  transition: margin ease-in-out 500ms;
`;

export const Image = styled.img`
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
`;
