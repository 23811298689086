import styled from 'styled-components/macro';

const wrapperColor = ({ banner }) => {
  if (!banner) return 'transparent';

  return 'black';
};
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100%;
  background-color: ${wrapperColor};
`;

const bannerHeight = ({ viewport }) => Math.ceil((viewport.width / 16) * 9);
export const Banner = styled.img`
  width: 100%;
  height: ${bannerHeight}px;
  object-fit: cover;
  margin-bottom: -24px;
`;

const containerRadius = ({ banner }) => {
  if (!banner) return 0;

  return '24px 24px 0 0';
};
const containerColor = ({ color }) => color;
export const Container = styled.div`
  padding: 2rem;
  z-index: 1;
  flex-grow: 1;
  background-color: ${containerColor};
  border-radius: ${containerRadius};
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const headerColor = ({ fontColor }) => fontColor;
const headerFontSize = ({ banner }) => {
  if (banner) return '1.6rem';
  return '2rem';
};
const headerSpacing = ({ banner }) => {
  if (banner) return 0;
  return '1rem 0';
};
export const Header = styled.div`
  text-align: center;
  color: ${headerColor};
  font-size: ${headerFontSize};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${headerSpacing};
  margin-bottom: 1em;

  &:empty {
    display: none;
  }

  h1 {
    font-size: 1em;
    margin: 0;
    font-weight: 700;
    text-align: center;
    width: 100%;
    overflow-wrap: anywhere;

    &:not(:last-child) {
      margin-bottom: .6rem;
    }
  }

  h2 {
    margin: 0;
    font-size: .5em;
    font-weight: 400;
    text-align: center;
    width: 100%;
    overflow-wrap: anywhere;
  }
`;
export const Content = styled.div`
  flex-grow: 1;
`;
