import React from 'react';
import useActiveState from '../../hooks/active-state';
import InputField from '../InputField';

import * as Styled from './AuthoredInputField.styled';

const AuthoredInputField = ({
  author = '',
  authorLabel = 'Author',
  authorMaxLength = 35,
  showAuthor = true,
  onChangeAuthor = () => {},
  inputRef = undefined,
  ...props
}) => {
  const { hasError } = props;

  const handleChange = ({ target }) => onChangeAuthor(target.value);

  return (
    <Styled.Container>
      {showAuthor && (
        <Styled.AuthorWrapper>
          <Styled.AuthorLabel hasError={hasError}>
            {authorLabel}
          </Styled.AuthorLabel>
          <Styled.AuthorInput
            value={author}
            maxLength={authorMaxLength}
            onChange={handleChange}
            placeholder="Your Name"
          />
          <Styled.AuthorLength>
            <span>{author.length}</span>&nbsp;/&nbsp;<span>{authorMaxLength}</span>
          </Styled.AuthorLength>
        </Styled.AuthorWrapper>
      )}
      <InputField inputRef={inputRef} {...props} />
    </Styled.Container>
  );
};
export default AuthoredInputField;
