import { useMemo, useState } from 'react';

const useScrollDetector = (ref) => {
  const [top, setTop] = useState(0);
  const bottom = useMemo(() => {
    if (!ref || !ref.current) return 0;
    const height = ref.current.scrollHeight;
    const offset = ref.current.clientHeight + top;
    return height + offset;
  }, [top]);

  const onScroll = () => {
    if (!ref || !ref.current) {
      return;
    }

    setTop(ref.current.scrollTop);
  };

  return [onScroll, { top, bottom }];
};
export default useScrollDetector;
