import { gql } from '@apollo/client';

const PROCESS_PHONE_NUMBER = gql`
    mutation processPhonenumber($payload:  PaymentPhoneInput!){
        processPhonenumber(payload: $payload) {
            success,
            message,
            pinRequired,
        }
    }
`;

export default PROCESS_PHONE_NUMBER;
