import React, { useContext } from 'react';
import CommentErrorModal from '../../../components/Module/CommentModule/Modal/CommentError';

import { ModalContext } from '../../../context/modal-context';

export default {};
export const useHandleError = () => {
  const { showModal, closeModal } = useContext(ModalContext);
  const handleError = (err) => {
    if (!err.graphQLErrors || !err.graphQLErrors.length) {
      showModal(
        <CommentErrorModal
          errorTitle="Unknown Error"
          errorMessage={err.message || err}
          setShowModal={closeModal}
        />,
      );
      return;
    }

    showModal(
      <CommentErrorModal
        errorTitle="Unable to post comment"
        errorMessage={err.graphQLErrors[0].message}
        setShowModal={closeModal}
      />,
    );
  };

  return { handleError };
};
