import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ModuleStatus } from '../../../enums/module.enums';

import { colors, sizes } from '../../../library/styleAbstracts/variables';
import { canVote, incrementVoteCount } from '../../../library/voteLimit';

import getModuleTitles from '../../../library/titlesHelper';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import useShow from '../../../hooks/useShow';

import VOTE_ADD from '../../../graphql/mutations/voteAdd';
import VOTES from '../../../graphql/queries/votes';

const { headerHeight, footerHeight } = sizes;

const CReactionModule = styled.div`
  background-color: ${colors.defaultModuleBackground};
  display: flex;
  flex-direction: column;
  height: ${(props) => `calc(${props.height}px - (${props.showHeader ? headerHeight : '0px'} +
   ${props.showFooter ? footerHeight : '0px'}));`};
  padding: 1rem;
  position: relative;
`;

// const CReactionModuleContentBackground = styled.div`
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 15%;
//   left: 0;
//   background-color: white;
//   z-index: 0;
//   border-bottom-left-radius: 35px;
//   border-bottom-right-radius: 35px;
// `;

const CReactionHeader = styled.header`
  text-align: center;
  margin-top: auto;
  margin-bottom: 0;
  position: relative;
  z-index: 1;
`;

const CReactionTitle = styled.h1`
  font-size: 1rem;
`;

const CReactionSubtitle = styled.p`
  font-size: 0.8rem;
  font-weight: 400;
  margin: 0 0 0.5rem;
`;

const CReactions = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: auto;
  margin-bottom: 10%;
`;

const CReaction = styled.button`
  flex-grow: 1;
  flex-basis: 0;
  background-color: ${colors.defaultModuleBackground};
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  border: 2px solid ${colors.reactionBorderColor};
  border-radius: 8px;
  width: 80px;
  height: 80px;
  &:not(:first-child) {
    margin-left: 0.5rem;
  }
  &:disabled {
    position: relative;
    &:after {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: ${colors.reactionBorderColor};
      opacity: 0.3;
    }
  }
`;

const CReactionAnimation = styled.div`
  height: 40%;
  margin-top: ${sizes.navButtonHeight};
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

const CReactionAnimationIcon = styled.div`
  width: 80px;
  height: 80px;
  display: block;
  transform: scale(1);
  margin: 0 auto;
  ${(props) => props.image === 'clapping-sand' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${props.image}.png);
    width: 100px;
    height: 106px;
    animation: arClapSand 1s steps(25) 2;
  `}
  ${(props) => props.image === 'clapping-yellow' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${props.image}.png);
    width: 115px;
    height: 130px;
    animation: arClapYellow 1s steps(31) 2;
  `}
  ${({ image }) => image === 'dancing-sand' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}.png);
    width: 122px;
    height: 125px;
    animation: arDancingSand 1.7s steps(80) 2;
  `}
  ${({ image }) => image === 'dancing-yellow' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}.png);
    width: 125px;
    height: 118px;
    animation: arDancingYellow 1.7s steps(80) 2;
  `}
  ${({ image }) => image === 'sleepy-sand' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}.png);
    width: 70px;
    height: 144px;
    animation: arSleepySand 3s steps(96) 2;
  `}
  ${({ image }) => image === 'sleepy-yellow' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}.png);
    width: 72px;
    height: 144px;
    animation: arSleepyYellow 3s steps(99) 2;
  `}
  ${({ image }) => image === 'heart-sand' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}.png);
    width: 131px;
    height: 217px;
    animation: arHeartSand 2.5s steps(97) 2;
  `}
  ${({ image }) => image === 'heart-yellow' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}.png);
    width: 120px;
    height: 217px;
    animation: arHeartYellow 2.5s steps(130) 2;
  `}
  ${(props) => props.image === 'clapping' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${props.image}.png);
    width: 113px;
    height: 130px;
    animation: arClap 1.7s steps(60) 2;
  `}
  ${({ image }) => image === 'sad' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}.png);
    width: 192px;
    height: 81px;
    animation: arSad 3s steps(126) 2;
  `}
  ${({ image }) => image === 'mad' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}.png);
    width: 70px;
    height: 115px;
    animation: arMad 3s steps(110) 2;
  `}
  ${({ image }) => image === 'heart' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}.png);
    width: 187px;
    height: 300px;
    animation: arHeart 3s steps(91) 2;
  `}
  ${({ image }) => image === 'danfoss-agreed' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}.png);
    width: 150px;
    height: 370px;
    animation: arDanfossAgreed 3s steps(75) 2;
  `}
  ${({ image }) => image === 'danfoss-clapping' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}.png);
    width: 150px;
    height: 350px;
    animation: arDanfossClapping 3s steps(64) 2;
  `}
  ${({ image }) => image === 'danfoss-party' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}.png);
    width: 150px;
    height: 370px;
    animation: arDanfossParty 3s steps(89) 2;
  `}
  ${({ image }) => image === 'kone-care' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}.png);
    width: 200px;
    height: 200px;
    animation: arKoneCare 3s steps(120) 2;
  `}
  ${({ image }) => image === 'kone-collaboration' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}.png);
    width: 200px;
    height: 200px;
    animation: arKoneCollaboration 3s steps(120) 2;
  `}
  
  ${({ image }) => image === 'kone-courage' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}.png);
    width: 200px;
    height: 200px;
    animation: arKoneCourage 3s steps(120) 2;
  `}
  
  ${({ image }) => image === 'kone-customer' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}.png);
    width: 200px;
    height: 200px;
    animation: arKoneCustomer 3s steps(120) 2;
  `}
  
  ${({ image }) => image === 'cannes_clapping' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}_sprite.png);
    width: 192px;
    height: 370px;
    animation: arCannesClapping 3s steps(65) 1;
  `}
  
  ${({ image }) => image === 'cannes_confetti' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}_sprite.png);
    width: 192px;
    height: 370px;
    animation: arCannesConfetti 3s steps(77) 1;
  `}
  
  ${({ image }) => image === 'cannes_flame' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}_sprite.png);
    width: 192px;
    height: 370px;
    animation: arCannesFlame 3s steps(65) 1;
  `}
  
  ${({ image }) => image === 'cannes_heart' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}_sprite.png);
    width: 192px;
    height: 370px;
    animation: arCannesHeart 3s steps(77) 1;
  `}
  
  ${({ image }) => image === 'cannes_juan' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}_sprite.png);
    width: 144px;
    height: 278px;
    animation: arCannesJuan 3s steps(125) 1;
  `}
  
  ${({ image }) => image === 'cannes_lions' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}_sprite.png);
    width: 192px;
    height: 370px;
    animation: arCannesLions 3s steps(112) 1;
  `}
  
  ${({ image }) => image === 'cannes_perfect' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}_sprite.png);
    width: 192px;
    height: 370px;
    animation-name: arCannesPerfect;
    animation-duration: 3s;
    animation-iteration-count: 1;
    animation-timing-function: steps(111);
    animation-fill-mode: forwards;
  `}
  
  ${({ image }) => image === 'cannes_tina' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}_sprite.png);
    width: 144px;
    height: 278px;
    animation: arCannesTina 3s steps(125) 1;
  `}

  ${({ image }) => image === 'eas_clap' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}_sprite.png);
    width: 200px;
    height: 200px;
    animation: arEasClap 3s steps(120) 1;
  `}

  ${({ image }) => image === 'eas_heart' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}_sprite.png);
    width: 200px;
    height: 200px;
    animation: arEasHeart 3s steps(120) 1;
  `}

  ${({ image }) => image === 'eas_rand' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}_sprite.png);
    width: 200px;
    height: 200px;
    animation: arEasRand 3s steps(120) 1;
  `}

  ${({ image }) => image === 'eas_pall' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}_sprite.png);
    width: 200px;
    height: 200px;
    animation: arEasPall 3s steps(120) 1;
  `}


  ${({ image }) => image === 'ascential_clap' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}.png);
    width: 194px;
    height: 370px;
    animation: arAscentialClap 3s steps(65) 1;
  `}
  ${({ image }) => image === 'ascential_heart' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}.png);
    width: 194px;
    height: 370px;
    animation: arAscentialHeart 3s steps(77) 1;
  `}
  ${({ image }) => image === 'ascential_perfect' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}.png);
    width: 194px;
    height: 370px;
    animation-name: arAscentialPerfect;
    animation-duration: 3s;
    animation-iteration-count: 1;
    animation-timing-function: steps(111);
    animation-fill-mode: forwards;
  `}
  ${({ image }) => image === 'ascential_flames' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}.png);
    width: 194px;
    height: 370px;
    animation: arAscentialFlames 3s steps(65) 1;
  `}
  ${({ image }) => image === 'ascential_confetti' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}.png);
    width: 194px;
    height: 370px;
    animation: arAscentialConfetti 3s steps(77) 1;
  `}

  ${({ image }) => image === 'coca-cola_cola' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}.png);
    width: 260px;
    height: 376px;
    animation: arCocaCola-Cola 3s steps(115) 1;
  `}
  ${({ image }) => image === 'coca-cola_sprite' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}.png);
    width: 250px;
    height: 270px;
    animation: arCocaCola-Sprite 3s steps(95) 1;
  `}
  ${({ image }) => image === 'coca-cola_fanta' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}.png);
    width: 250px;
    height: 250px;
    animation: arCocaCola-Fanta 3s steps(38) 1;
  `}
  ${({ image }) => image === 'coca-cola_water' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}.png);
    width: 250px;
    height: 250px;
    animation: arCocaCola-Water 3s steps(90) 1;
  `}
  ${({ image }) => image === 'heart_green' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}.png);
    width: 200px;
    height: 200px;
    animation: arHeart_green 2s steps(64) 1;
  `}
  ${({ image }) => image === 'heart_yellow' && `
    background-image: url(${process.env.PUBLIC_URL}/images/reactions/animated/${image}.png);
    width: 200px;
    height: 200px;
    animation: arHeart_yellow 2s steps(64) 1;
  `}
`;

const ReactionModule = (props) => {
  const { module } = props;
  const {
    currentModule,
    showInfo,
    showHeader,
    trackingId,
    modules,
  } = useShow();
  const { id } = module;
  const { t } = useTranslation();
  const [createVote, { loading }] = useMutation(VOTE_ADD);
  const [animation, setAnimation] = useState(false);
  const [requestResults, setRequestResults] = useState(false);
  const [error, setError] = useState(false);
  const [hasLimit, setLimit] = useState(!canVote(module));
  const [limitClasses, setLimitClasses] = useState([]);
  const [bannerTitle, setBannerTitle] = useState('');
  const [bannerSubtitle, setBannerSubtitle] = useState('');
  const { height } = useWindowDimensions();
  const {
    refetch,
  } = useQuery(VOTES, {
    variables: {
      module_id: module.id,
    },
    pollInterval: (showInfo && showInfo.poll_interval) ? showInfo.poll_interval : 10000,
    skip: !requestResults,
  });

  useEffect(() => {
    if (currentModule.id === module.id) {
      setRequestResults(true);
    }
  }, [currentModule]);

  useEffect(() => {
    if (module && typeof module !== 'undefined') {
      const { moduleTitle, moduleSubtitle } = getModuleTitles(module);
      setBannerTitle(moduleTitle);
      setBannerSubtitle(moduleSubtitle);
    }
  }, [module]);
  const disableAnimation = async () => {
    setAnimation(false);
  };
  const clickReaction = async (option) => {
    const { status } = module;
    if (status === ModuleStatus.Live && canVote(module)) {
      await disableAnimation();
      setAnimation(option);
      const payload = {
        question_id: id,
        answer_id: option.id,
        weight: 1,
        is_paid: false,
        tracking_id: trackingId,
      };
      try {
        const response = await createVote({
          variables: {
            payload,
            showResults: true,
          },
        });
        if (response.data.createVote.success) {
          incrementVoteCount(module);
          await refetch();
          setError(false);
        }
      } catch (e) {
        setError(true);
      }
    }
    if (!canVote(module)) {
      setLimit(true);
      setLimitClasses(['animated', 'wobble', 'slow']);
    }
  };
  const renderReactions = () => (
    module.options.map((option, index) => (
      <div key={option.id}>
        <CReaction
          onClick={() => clickReaction(option)}
          style={{ animationDelay: `${index / 8}s` }}
          className="animated fadeInUp"
          disabled={module.status !== ModuleStatus.Live || loading}
        >
          <img
            style={{
              width: '100%',
              height: 'auto',
            }}
            alt={option.image}
            src={`${process.env.PUBLIC_URL}/images/reactions/${option.image}.png`}
          />

        </CReaction>
      </div>
    ))
  );

  return (
    <CReactionModule id={`module-${id}`} height={height} showHeader={showHeader} showFooter={modules.length > 1}>
      {!error && (
        <CReactionAnimation>
          {hasLimit && (
            <div
              className={limitClasses.join(' ')}
              onAnimationEnd={() => setLimitClasses([])}
            >
              {t('modules.reaction.reaction_limit')}
            </div>
          )}
          {animation && !hasLimit && (
            <CReactionAnimationIcon image={animation.image} />
          )}
        </CReactionAnimation>
      )}
      <CReactionHeader>
        <CReactionTitle>{bannerTitle}</CReactionTitle>
        <CReactionSubtitle>{bannerSubtitle}</CReactionSubtitle>
      </CReactionHeader>
      <CReactions>
        {renderReactions()}
      </CReactions>
    </CReactionModule>
  );
};

export default ReactionModule;
