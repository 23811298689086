import { useContext } from 'react';
import { RootContext } from '../context/root-context';

const useWelcome = () => {
  const [state, setState] = useContext(RootContext);
  const setTermsAccepted = (accepted) => {
    setState((currentState) => ({
      ...currentState,
      accepted,
    }));

    localStorage.setItem('terms_accepted', 'true');
  };
  const setTermsStorage = (accepted) => {
    if (accepted) {
      localStorage.setItem('terms_accepted', 'true');
    } else {
      localStorage.removeItem('terms_accepted');
    }
  };

  const isTermsAccepted = () => (
    localStorage.getItem('terms_accepted')
  );

  return {
    accepted: state.accepted,
    setTermsAccepted,
    setTermsStorage,
    isTermsAccepted,
  };
};

export default useWelcome;
