import ReactGA from 'react-ga';

const hasPermission = () => typeof process.env.REACT_APP_GA_TAG !== 'undefined';

const initializeGoogleAnalytics = () => {
  if (hasPermission()) {
    ReactGA.initialize(process.env.REACT_APP_GA_TAG);
  }
};

const trackPageView = (title) => {
  if (hasPermission()) {
    ReactGA.pageview(title);
  }
};

const trackCustomEvent = (event) => {
  if (hasPermission()) {
    ReactGA.event(event);
  }
};

export { initializeGoogleAnalytics, trackPageView, trackCustomEvent };
