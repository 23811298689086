import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useTranslation } from 'react-i18next';
import { trackCustomEvent } from '../../../library/gaHelper';

const VoteOptionsDisabled = ({ status, setVoteDisabledDisplay, optionName }) => {
  const classNameList = ['option__vote-options', 'inactive'];
  const { t } = useTranslation();
  let title;
  let subtitle;
  switch (status) {
    case 'NOT_STARTED':
      classNameList.push('not-started');
      title = t('modules.vote.not_started_title');
      subtitle = t('modules.vote.not_started_subtitle');
      trackCustomEvent({
        category: 'voting',
        action: 'Sees voting has not started',
        label: optionName,
      });
      break;
    case 'ENDED':
      classNameList.push('ended');
      title = t('modules.vote.ended_title');
      subtitle = t('modules.vote.ended_subtitle');
      trackCustomEvent({
        category: 'voting',
        action: 'Sees voting has ended',
        label: optionName,
      });
      break;
    default:
      title = t('modules.vote.vote_limit');
      break;
  }
  const onOptionClick = (event) => {
    event.stopPropagation();
    setVoteDisabledDisplay(false);
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setVoteDisabledDisplay(false);
      }}
    >
      <div
        className={classNameList.join(' ')}
        onClick={(event) => onOptionClick(event)}
        onKeyPress={(event) => onOptionClick(event)}
        role="button"
        tabIndex="-1"
      >
        <div className="vote-option__container animated fadeIn">
          <p className="message">{title}</p>
          {status !== 'ENDED' && (
            <p className="message sub">{subtitle}</p>
          )}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default VoteOptionsDisabled;
