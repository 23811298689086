import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import WORD_SUBMIT from '../../../graphql/mutations/submitWord';
import useShow from '../../../hooks/useShow';
import BottomInput from '../../BottomInput';
import CommentErrorModal from '../CommentModule/Modal/CommentError';

const AddWord = ({ onUpdate }) => {
  const { t } = useTranslation();
  const { currentModule: module, trackingId } = useShow();
  const [submitWord, { loading: loadingSubmit }] = useMutation(WORD_SUBMIT);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleError = (error) => {
    setErrorMessage(error);
    setShowErrorModal(true);
  };

  const handleSubmit = async (word) => {
    const payload = {
      module_id: module.id,
      text: word,
      is_paid: false,
    };

    // add tracking id to the payload if we have it
    if (trackingId) {
      payload.tracking_id = trackingId;
    }

    try {
      const response = await submitWord({ variables: { payload } });
      if (!response.data) {
        return false;
      }
      onUpdate();
    } catch (e) {
      if (e.graphQLErrors && e.graphQLErrors.length) {
        const [{ message }] = e.graphQLErrors;
        handleError(message);
        return false;
      }
      if (e.message) {
        handleError(e.message);
        return false;
      }

      handleError(e);
      return false;
    }
    return true;
  };

  return (
    <>
      <ReactModal
        isOpen={showErrorModal}
        onRequestClose={() => setShowErrorModal(false)}
        className="vote-modal animated zoomInUp"
      >
        <CommentErrorModal
          setShowModal={setShowErrorModal}
          errorTitle={t('modules.comment.error')}
          errorMessage={errorMessage}
        />
      </ReactModal>
      <BottomInput
        onSubmit={handleSubmit}
        options={{
          maxLength: 20,
          disabled: loadingSubmit,
        }}
        labels={{
          placeholder: t('modules.comment.form.word'),
          placeholderError: t('modules.comment.form.word_has_error'),
          send: t('modules.comment.form.send'),
        }}
      />
    </>
  );
};

export default AddWord;
