import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { sizes, colors } from '../../library/styleAbstracts/variables';
import useShow from '../../hooks/useShow';

const CFooter = styled.div`
  height: ${sizes.footerHeight};
  overflow: hidden;
  transition: height 0.25s ease-in-out;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-secondary)
`;

const CFooterMenu = styled.ul`
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
  height: ${sizes.footerHeight};
  align-items: center;
  justify-content: center;
`;

const CFooterMenuItem = styled.div`
  &:not(:first-child) {
    margin-left: 0.5rem;
  }
`;

const CFooterMenuLink = styled(NavLink)`
  color: ${colors.indicatorInactive};
  font-size: 0.35rem;
  transition: all 0.25s ease-in;
  vertical-align: middle;
  &.active {
    color: var(--color-primary);
  }
`;
const Footer = () => {
  const { modules, currentModule } = useShow();
  if (!modules) {
    return <div>Loading...</div>;
  }
  const renderFooterLinks = () => (
    modules.map((module) => (
      <CFooterMenuItem key={module.id}>
        <CFooterMenuLink to={`/${module.id}`}>
          <i className="fa fa-circle" />
        </CFooterMenuLink>
      </CFooterMenuItem>
    ))
  );
  if (currentModule.type === 'produt_launch') {
    return <footer />;
  }
  if (modules.length > 1) {
    return (
      <CFooter>
        <CFooterMenu>
          {renderFooterLinks()}
        </CFooterMenu>
      </CFooter>
    );
  }
  return (
    <footer />
  );
};

export default Footer;
