import { gql } from '@apollo/client';

const PROCESS_PIN = gql`
    mutation processPin($payload:  PaymentPinInput!){
        processPin(payload: $payload) {
            success,
            message,
        }
    }
`;

export default PROCESS_PIN;
