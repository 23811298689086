import React, { useMemo } from 'react';
import PerfectScrollBar from 'react-perfect-scrollbar';
import styled from 'styled-components/macro';
import useShow from '../../../hooks/useShow';
import useViewport from '../../../hooks/viewport';
import useWordCloud from '../../../hooks/word-cloud';

import ModuleHeader from '../../ModuleHeader';
import { perfectScrollBarOptions } from '../constants';
import AddWord from './AddWord';

import WordCloudResults from './WordCloudResults';

const containerHeight = ({ viewport }) => (
  `calc(${viewport.height}px - (${viewport.top}px + ${viewport.bottom}px))`
);
const ModuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: ${containerHeight};
  box-sizing: border-box;
  padding: 0;
  position: relative;
  background-color: #ebebeb;

  * {
    box-sizing: border-box;
  }
`;

const WordCloudModule = ({ module }) => {
  const { showTheme } = useShow();
  const viewport = useViewport();
  const colors = useMemo(
    () => [
      `#${showTheme.primary_color}`,
      `#${showTheme.secondary_color}`,
    ],
    [showTheme.primary_color, showTheme.secondary_color],
  );
  const [wordCloud, { loading, refetch }] = useWordCloud(module.id, { colors });

  const handleNewWord = () => refetch({ module_id: module.id });

  if (loading && !wordCloud) return 'Loading...';

  return (
    <PerfectScrollBar options={perfectScrollBarOptions}>
      <ModuleContainer viewport={viewport}>
        <ModuleHeader module={module} />
        <WordCloudResults data={wordCloud} />
        <AddWord onUpdate={handleNewWord} />
      </ModuleContainer>
    </PerfectScrollBar>
  );
};

export default WordCloudModule;
