import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import useActiveState from '../../hooks/active-state';
import * as Styled from './InputField.styled';

import InputFieldLimit from './InputFieldLimit';

const InputIcon = () => (
  <FontAwesomeIcon icon={ faPaperPlane } />
);

const InputField = ({
  value = '',
  placeholder = '',
  submitLabel = 'Submit',
  maxLength = undefined,
  hasError = false,
  disabled = false,
  submitDisabled = disabled,
  showLength = false,
  onChange = () => {},
  onSubmit = () => {},
  inputRef = undefined,
}) => {
  const [isActive, { focus: handleFocus, blur: handleBlur }] = useActiveState();

  const handleChange = ({ target }) => onChange(target.value);
  const handleSubmit = () => onSubmit(value);

  const handleKeyPress = (event) => {
    if (event.which !== 13 || event.shiftKey) return;

    event.preventDefault();
    handleSubmit();
  };

  return (
    <Styled.Container isActive={isActive} hasError={hasError}>
      <Styled.Wrapper>
        <Styled.TextareaWrapper>
          <Styled.Textarea
            onKeyPress={handleKeyPress}
            inputRef={inputRef}
            value={value}
            maxLength={maxLength}
            placeholder={placeholder}
            disabled={disabled}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </Styled.TextareaWrapper>
        <Styled.ButtonWrapper>
          <Styled.Button type="button" onClick={handleSubmit} disabled={submitDisabled}>
            {!submitDisabled && (
              <span>{submitLabel}</span>
            )}
            <InputIcon />
          </Styled.Button>
        </Styled.ButtonWrapper>
      </Styled.Wrapper>
      {showLength && (
        <InputFieldLimit length={value.length} maxLength={maxLength} />
      )}
    </Styled.Container>
  );
};
export default InputField;
