import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useWelcome from '../../../hooks/useWelcome';
import useShow from '../../../hooks/useShow';
import staticSource from '../../../library/staticSource';

const WelcomePage = () => {
  const { t } = useTranslation();
  const [isAccepted, setIsAccepted] = useState(false);
  const { episodeInfo } = useShow();
  const { setTermsAccepted, isTermsAccepted } = useWelcome();

  const iconClassList = ['far', 'fa-square'];
  const handleButtonClick = () => {
    setTermsAccepted(true);
  };

  if (isAccepted) {
    const index = iconClassList.indexOf('fa-square');
    if (index > -1) {
      iconClassList.splice(index);
    }
    iconClassList.push('fa-check-square');
  }
  if (isTermsAccepted()) {
    return <Redirect to="/" />;
  }
  return (
    <div className="page welcome">
      {episodeInfo && episodeInfo.image && (
        <div
          className="welcome-background"
          style={{
            backgroundImage: `url(${staticSource(episodeInfo.image)})`,
          }}
        />
      )}
      <div className="page-inner">
        <div className="page__title">
          <h1>{t('welcome.title')}</h1>
          <div className="page__subtitle">
            <h3>{t('welcome.subtitle')}</h3>
          </div>
        </div>
        <div className="page__conditions">
          { isAccepted && (
            <i className="fas fa-check animate-check" />
          )}
          <button type="button" aria-label="set-accepted" onClick={() => setIsAccepted(!isAccepted)}>
            <i className={iconClassList.join(' ')} />
          </button>
          {t('welcome.accept')} <Link to="/conditions">{t('welcome.terms_link')}</Link>
        </div>
        <div className="page__cta">
          <button type="button" disabled={!isAccepted} onClick={handleButtonClick}>
            {t('welcome.cta')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
