import React from 'react';
import useViewport from '../../hooks/viewport';
import * as Styled from './ModuleContainer.styled';

const ModuleContainer = ({
  color = '#002efc',
  fontColor = '#ffffff',
  banner = null,
  gradient = null,
  title = null,
  subtitle = null,
  children,
}) => {
  const viewport = useViewport();

  return (
    <Styled.Wrapper banner={banner}>
      {banner && <Styled.Banner viewport={viewport} src={banner} />}
      <Styled.Container color={color} gradient={gradient} banner={banner}>
        <Styled.Header banner={banner} fontColor={fontColor}>
          {title && <h1>{title}</h1>}
          {subtitle && <h2>{subtitle}</h2>}
        </Styled.Header>
        <Styled.Content>
          {children}
        </Styled.Content>
      </Styled.Container>
    </Styled.Wrapper>
  );
};
export default ModuleContainer;
