import { useMemo } from 'react';
import useShow from './useShow';

const colorOrDefault = (color, defaultColor = 'black') => {
  if (color) return `#${color}`;
  return defaultColor;
};

const useShowColors = () => {
  const { showTheme } = useShow();
  const primaryColor = useMemo(
    () => colorOrDefault(showTheme.primary_color),
    [showTheme],
  );
  const secondaryColor = useMemo(
    () => colorOrDefault(showTheme.secondary_color),
    [showTheme],
  );

  return [primaryColor, secondaryColor];
};

export default useShowColors;
