import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import CommentErrorModal from '../../../components/Module/CommentModule/Modal/CommentError';
import CommentSuccessModal from '../../../components/Module/CommentModule/Modal/CommentSuccess';
import ModuleHeader from '../../../components/ModuleHeader/ModuleHeader';
import { useScrollingHeaderImage } from '../../../components/ScrollingHeaderImage';
import Thread from '../../../components/Thread';
import { ModalContext } from '../../../context/modal-context';
import { ModuleType } from '../../../enums/module.enums';
import { useValidateComment } from '../../../hooks/comments';
import useSubmitComment from '../../../hooks/comments/useSubmitComment';
import useScrolledToBottom from '../../../hooks/useScrolledToBottom';
import useShow from '../../../hooks/useShow';
import useShowColors from '../../../hooks/useShowColors';
import useViewer from '../../../hooks/viewers';
import useViewport from '../../../hooks/viewport';
import AddComment from './AddComment';
import { useHandleError } from './CommentsModule.hooks';

import * as Styled from './CommentsModule.styled';
import CommentsModuleContent from './CommentsModuleContent';
import useThreadLoader from './hooks/useThreadLoader';

const useSingleAlert = (callback = () => {}, dependencies = []) => {
  const [run, setRun] = useState(false);
  return useCallback(() => {
    if (run) return;

    callback();
    setRun(true);
  }, [run, setRun, ...dependencies]);
};

// TODO: Rename comments to chat module
const CommentsModule = ({ module }) => {
  const { t } = useTranslation();
  const { showInfo, currentModule, next } = useShow();
  const viewport = useViewport();
  const [viewer, { userId, setUsername }] = useViewer();
  const [validateComment] = useValidateComment();
  const [submitComment] = useSubmitComment();
  const { handleError } = useHandleError();
  /** @type {React.RefObject<HTMLTextAreaElement>} */
  const inputRef = useRef();

  const loadComments = useMemo(
    () => currentModule.id === module.id,
    [currentModule, module],
  );
  const [threads, {
    addThread,
    update: updateThreads,
    loading: loadingThreads,
    loadMore: loadMoreThreads,
  }] = useThreadLoader(module.id, userId, loadComments);
  const scrollHandler = useCallback(loadMoreThreads, [loadMoreThreads]);
  const [primaryColor] = useShowColors();
  const addCommentRef = useRef();
  const [replyingTo, setReplyingTo] = useState(0);
  const [scrollLoader] = useScrolledToBottom(scrollHandler);
  const [HeaderImage, {
    props: headerImageProps,
    ref: headerImageRef,
    scrollDetector: handleHeaderImageScroll,
  }] = useScrollingHeaderImage(module.image);

  useEffect(() => {
    if (!replyingTo) return;
    if (!inputRef.current) return;

    inputRef.current.focus();
  }, [replyingTo, inputRef.current]);

  const { showModal, closeModal } = useContext(ModalContext);
  const [showAuthor, setShowAuthor] = useState(false);
  const [author, setAuthor] = useState('');
  const [value, setValue] = useState('');
  const history = useHistory();

  const handleNextModule = useCallback(() => {
    if (!next) return;
    if (next === module.id) return;

    history.push(`/${next}`);
  }, [next, module.id, history]);

  const showSuccessModal = useSingleAlert(() => {
    if (next === module.id) {
      showModal(
        <CommentSuccessModal
          setShowModal={closeModal}
        />,
      );
      return;
    }

    showModal(
      <CommentSuccessModal
        setShowModal={closeModal}
        onNextModule={handleNextModule}
      />,
    );
  }, [showModal, handleNextModule, module.id, next]);

  useEffect(() => {

    if (!viewer) {
      return;
    }

    if (!viewer.username) {
      setShowAuthor(true);
      return;
    }

    setAuthor(viewer.username);
  }, [viewer]);

  useEffect(() => {
    if (!loadComments) {
      return () => {};
    }

    const intervalDuration = (showInfo && showInfo.poll_interval) ? showInfo.poll_interval : 10000;
    const interval = setInterval(updateThreads, intervalDuration);
    return () => clearInterval(interval);
  }, [loadComments, showInfo, updateThreads]);

  const handleSubmit = async () => {
    if (!author) {
      showModal(
        <CommentErrorModal
          errorTitle="Missing name"
          errorMessage="Please enter a name to comment"
          setShowModal={closeModal}
        />,
      );
      return;
    }

    const payload = {
      module_id: module.id,
      text: value,
      is_paid: false,
      parent_id: replyingTo,
      uid: userId,
      voter_id: localStorage.getItem('voterId') ? +localStorage.getItem('voterId') : null
    };

    if (Number.isNaN(payload.parent_id)) {
      payload.parent_id = 0;
    }

    try {
      if (viewer.username !== author) {
        await setUsername(author);
        setShowAuthor(false);
      }
    } catch (err) {
      handleError(err);
    }

    try {
      const isCommentValid = await validateComment(payload);
      if (isCommentValid) {
        const comment = await submitComment(payload);
        setValue('');

        if (module.show_new_comments) {
          addThread(comment);
        }

        if (replyingTo) {
          setReplyingTo(0);
        }

        showSuccessModal();
      }
    } catch (err) {
      handleError(err);
    }
  };
  const handleReplyTo = useCallback((thread) => {
    if (replyingTo === thread.id) {
      return;
    }
    setReplyingTo(thread.id);
  });
  const handleCloseReply = useCallback(() => {
    setReplyingTo(0);
  });

  const replyingThread = useMemo(() => {
    if (!replyingTo) return null;
    return threads.find((thread) => thread.id === replyingTo);
  }, [replyingTo]);

  const handleScrollThreads = useCallback((event) => {
    handleHeaderImageScroll(event);
    scrollLoader(event);
  }, [handleHeaderImageScroll, scrollLoader]);

  return (
    <Styled.Container viewport={viewport}>
      <HeaderImage {...headerImageProps} />
      <ModuleHeader module={module} />
      <CommentsModuleContent
        module={module}
        threads={threads}
        loading={loadingThreads}
        onReply={handleReplyTo}
        onScroll={handleScrollThreads}
        containerRef={headerImageRef}
      />
      <Styled.InputWrapper>
        {replyingTo > 0 && (
          <Styled.ReplyWrapper>
            <div className="reply-controls">
              <span>Replying to:</span>
              <button type="button" onClick={handleCloseReply} className="reply-close">
                close
              </button>
            </div>
            <Thread
              author={replyingThread.author}
              moderatedAuthorColor={primaryColor}
              upvoteColor={primaryColor}
              timestamp={replyingThread.createdAt}
              content={replyingThread.text}
              isModerated={replyingThread.is_moderated}
              enableReplies={false}
              enableUpvotes={false}
            />
          </Styled.ReplyWrapper>
        )}
        <AddComment
          authorLabel={t('modules.comment.form.author')}
          placeholder={useMemo(
            () => (
              module.type === ModuleType.Comments
                ? t('modules.comment.form.comment')
                : t('modules.comment.form.question')
            ), [module, t],
          )}
          submitLabel={t('modules.comment.form.send')}
          showAuthor={showAuthor}
          onSubmit={handleSubmit}
          value={value}
          author={author}
          onChange={setValue}
          onChangeAuthor={setAuthor}
          controlRef={addCommentRef}
          inputRef={inputRef}
        />
      </Styled.InputWrapper>
    </Styled.Container>
  );
};
export default CommentsModule;
