import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useShow from '../../hooks/useShow';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Tracking = ({ children }) => {
  const { setTrackingId } = useShow();
  const query = useQuery();
  const tracking_id = query.get('tracking_id');

  useEffect(() => {
    if (tracking_id) {
      setTrackingId(tracking_id);
    }
  }, [tracking_id]);

  return (
    <>
      {children}
    </>
  );
};

export default Tracking;
