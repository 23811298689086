import React, { useState, useEffect } from 'react';
import useShow from '../../../hooks/useShow';

const OptionResultProgress = ({ module, percentage }) => {
  if (!module || !module.show_results) {
    return (
      <div className="vote-result__progress-bar" />
    );
  }

  return (
    <div className="vote-result__progress-bar">
      <div
        className="vote-result__progress-bar-result"
        style={{
          width: `${percentage}%`,
        }}
      />
    </div>
  );
};

const OptionResult = ({ data, total, index }) => {
  const { title, totalWeight } = data;
  const { currentModule } = useShow();
  const maxPercentage = Math.round((totalWeight * 100) / total);
  const [percentage, setPercentage] = useState(undefined);
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!Number.isNaN(maxPercentage)) {
        setPercentage(maxPercentage);
      }
    }, 100);
    return () => clearTimeout(timeout);
  });
  return (
    <li
      className="vote-result animated fadeInLeft"
      style={{
        animationDelay: `${index / 12}s`,
      }}
    >
      <div className="vote-result__title">
        {title}
        {currentModule.show_results && (
          <span className="vote-result__count">
            &nbsp;({percentage}%)
          </span>
        )}
      </div>
      <OptionResultProgress module={currentModule} percentage={percentage} />
    </li>
  );
};

export default OptionResult;
