import { useMutation } from '@apollo/client';
import COMMENT_CREATE from '../../graphql/mutations/createComment';

const useSubmitComment = () => {
  const [createComment, { loading, error }] = useMutation(COMMENT_CREATE);

  const submitComment = async (payload) => {
    const response = await createComment({ variables: { payload } });

    if (response.data) {
      return response.data.createComment;
    }

    return null;
  };

  return [submitComment, { loading, error }];
};

export default useSubmitComment;
