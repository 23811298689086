import { useContext } from 'react';
import { RootContext } from '../context/root-context';
import hexToRgb from '../library/colorConverter';

const useShow = () => {
  const [state, setState] = useContext(RootContext);

  // this function will recalculate the navigation
  const recalculateNavigation = (currentModule, modules) => {
    // here is the magic. we get the currentModule, so we know which module is visible on the screen
    // with this info, we can determine what the previous and next modules are
    if (modules.length < 1) {
      return {
        previous: false,
        next: false,
        currentModule: false,
      };
    }
    const index = modules.findIndex((module) => module.id === currentModule.id);

    // if we are on first item, then there is no previous
    let previousIndex = index - 1;
    if (previousIndex < 0) {
      previousIndex = 0;
    }

    // if we are on last item, then there is no next
    let nextIndex = index + 1;
    if (nextIndex > modules.length - 1) {
      nextIndex = modules.length - 1;
    }

    return {
      previous: modules[previousIndex].id,
      next: modules[nextIndex].id,
      currentModule,
    };
  };

  const setShowHeader = (show_header) => {
    setState((currentState) => ({
      ...currentState,
      showHeader: show_header,
    }));
  };

  const setShowMenu = (show_menu) => {
    setState((currentState) => ({
      ...currentState,
      showMenu: show_menu,
    }));
  };

  const setShowInfo = (showInfo) => {
    setState((currentState) => ({
      ...currentState,
      showInfo,
    }));
  };

  // set the modules in the store. this is called when we retrieve data from GraphQL
  const setModules = (modules) => {
    // we need the navigation here, so we know what the previous and next buttons will do
    const navigation = recalculateNavigation(modules[0], modules);

    setState((currentState) => ({
      ...currentState,
      ...navigation,
      modules,
    }));
  };

  // update the active module in the store
  const updateActiveModule = (currentModule) => {
    // navigation has changed, so recalculate it
    const navigation = recalculateNavigation(currentModule, state.modules);

    // update the state. this will trigger every component listening to the prev and next values
    setState((currentState) => ({
      ...currentState,
      ...navigation,
    }));
  };

  const setEpisodeInfo = (episodeInfo) => {
    setState((currentState) => ({
      ...currentState,
      episodeInfo,
    }));
  };

  const setShowTheme = (showTheme) => {
    // Settings css variables
    document
      .documentElement.style.setProperty('--color-primary-rgb-string', hexToRgb(`#${showTheme.primary_color}`));
    document
      .documentElement.style.setProperty('--color-secondary-rgb-string', hexToRgb(`#${showTheme.secondary_color}`));
    document
      .documentElement.style.setProperty('--color-primary', `#${showTheme.primary_color}`);
    document
      .documentElement.style.setProperty('--color-secondary', `#${showTheme.secondary_color}`);
    document
      .documentElement.style.setProperty('--color-text-on-primary', `#${showTheme.text_on_primary}`);
    document
      .documentElement.style.setProperty('--color-text-on-secondary', `#${showTheme.text_on_secondary}`);
    // Settings state
    setState((currentState) => ({
      ...currentState,
      showTheme,
    }));
  };

  const setLatestUpdate = (updated_at) => {
    setState((currentState) => ({
      ...currentState,
      updated_at,
    }));
  };

  const setLanguage = (language) => {
    setState((currentState) => ({
      ...currentState,
      language,
    }));
  };

  const setTrackingId = (tracking_id) => {
    setState((currentState) => ({
      ...currentState,
      tracking_id,
    }));
  };

  const setWhitelabel = (whitelabel) => {
    setState((currentState) => ({
      ...currentState,
      whitelabel,
    }));
  };

  return {
    setLanguage,
    setShowInfo,
    setShowHeader,
    setLatestUpdate,
    setShowTheme,
    setEpisodeInfo,
    setModules,
    setTrackingId,
    updateActiveModule,
    setShowMenu,
    setWhitelabel,
    language: state.language,
    showInfo: state.showInfo,
    latestUpdate: state.updated_at,
    showTheme: state.showTheme,
    episodeInfo: state.episodeInfo,
    modules: state.modules,
    currentModule: state.currentModule,
    previous: state.previous,
    next: state.next,
    showHeader: state.showHeader,
    showMenu: state.showMenu,
    trackingId: state.tracking_id,
    whitelabel: state.whitelabel,
    company_name: state.company_name,
  };
};

export default useShow;
