import { useEffect, useState } from 'react';

const isScrolledToBottom = (target) => {
  const height = target.scrollHeight;
  const offset = target.clientHeight + target.scrollTop;
  return height - offset < 50;
};

const useScrolledToBottom = (handler) => {
  const [hasHitBottom, setHasHitBottom] = useState(false);

  useEffect(() => {
    if (hasHitBottom) {
      handler();
      setHasHitBottom(false);
    }
  }, [hasHitBottom, handler]);

  const onScroll = ({ nativeEvent }) => {
    const { target } = nativeEvent;
    if (isScrolledToBottom(target)) {
      setHasHitBottom(true);
    }
  };
  return [onScroll, { isBottom: hasHitBottom }];
};
export default useScrolledToBottom;
