import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useUpvoteContext } from '../../../../context/upvote-context';
import useGetComments from '../../../../hooks/comments/useGetComments';

const threadKey = (thread) => `${thread.uid}_${thread.createdAt}`;
const indexThreads = (threads) => threads.reduce((set, thread, index) => {
  if (!thread) return set;
  set.set(thread.id, index);
  return set;
}, new Map());
const useAddThread = (setThreads) => useCallback((thread) => {
  if (!thread.replies) {
    // eslint-disable-next-line no-param-reassign
    thread = {
      ...thread,
      replies: { comments: [] },
    };
  }

  if (thread.parent_id > 0) {
    setThreads((oldThreads) => oldThreads.map((target) => {
      if (target.id !== thread.parent_id) {
        return target;
      }
      const { comments: replies } = target.replies;
      return {
        ...target,
        replies: {
          comments: [...replies, thread],
        },
      };
    }));
    return;
  }

  setThreads((oldThreads) => [
    thread,
    ...oldThreads,
  ]);
});
const processNewThreads = (comments, threadIndex, threads) => {
  const newThreads = [...threads];
  // filter new data only, and partition to comments and replies
  const [newReplies, newComments] = _.partition(
    comments.filter((comment) => !threadIndex.has(comment.id)),
    (comment) => comment.parent_id > 0,
  );

  // index the new threads
  newComments.forEach((comment) => {
    threadIndex.set(comment.id, newThreads.length);
    newThreads.push(comment);
  });

  // merge the replies
  const uniqueReplies = newReplies.reduce((unique, reply) => {
    const key = threadKey(reply);
    const parentIndex = threadIndex.get(reply.parent_id);
    if (Number.isNaN(parentIndex)) return unique;

    const parent = newThreads[parentIndex];
    if (!parent) return unique;

    const existing = unique[key] || {};
    return {
      ...unique,
      [key]: { ...existing, ...reply },
    };
  }, {});

  // group the replies by parent
  const groupedReplies = Object.values(uniqueReplies).reduce((grouped, reply) => {
    const existing = grouped[reply.parent_id] || [];
    return {
      ...grouped,
      [reply.parent_id]: [
        ...existing,
        reply,
      ],
    };
  }, {});

  return newThreads.map((thread) => {
    const replies = groupedReplies[thread.id];
    if (!replies) return thread;

    const combinedReplies = _.flatten([
      thread.replies.comments,
      replies,
    ]).reduce((combined, reply) => {
      const key = threadKey(reply);
      const existingReply = combined[key] || {};
      return {
        ...combined,
        [key]: { ...existingReply, ...reply },
      };
    }, {});
    return {
      ...thread,
      replies: {
        comments: _.orderBy(
          _.values(combinedReplies),
          ['createdAt'],
          ['desc'],
        ),
      },
    };
  });
};
const processUpdatedThreads = (moduleId, comments, threadIndex, threads) => {
  const [updatedReplies, updatedComments] = _.partition(
    comments.filter((comment) => threadIndex.has(comment.id)),
    (comment) => comment.parent_id > 0,
  );
  const updatedIndex = updatedComments.reduce((map, thread, index) => map.set(thread.id, index), new Map());

  return threads.map((thread) => {
    if (!updatedIndex.has(thread.id)) return thread;

    const commentIndex = updatedIndex.get(thread.id);
    const updatedThread = updatedComments[commentIndex];
    const oldReplies = thread.replies ? thread.replies.comments : [];
    const newReplies = updatedThread.replies ? updatedThread.replies.comments : [];
    const replies = [...oldReplies, ...newReplies, ...updatedReplies].reduce((out, reply) => {
      const key = threadKey(reply);
      if (out[key]) {
        return {
          ...out,
          [key]: { ...out[key], ...reply },
        };
      }
      return { ...out, [key]: { ...reply } };
    }, {});

    return {
      ...thread,
      ...updatedThread,
      replies: {
        comments: _.orderBy(
          _.values(replies),
          ['createdAt'],
          ['desc'],
        ),
      },
    };
  });
};
const useThreadLoader = (moduleId, uid, autoload = true) => {
  const [upvotes, setUpvote] = useUpvoteContext();
  const [threads, setThreads] = useState([]);
  const [comments, {
    refetch, loadMore, update,
    loading, error,
  }] = useGetComments(moduleId, uid, autoload);

  useEffect(() => {
    // stop if no comments
    if (!comments) return;

    const threadIndex = indexThreads(threads);

    const newThreads = processNewThreads(comments, threadIndex, threads);

    const updatedThreads = processUpdatedThreads(moduleId, comments, threadIndex, newThreads);

    setThreads((oldThreads) => {
      const unique = [...oldThreads, ...newThreads, ...updatedThreads].reduce((result, thread) => {
        const key = threadKey(thread);
        if (result[key]) {
          return {
            ...result,
            [key]: { ...result[key], ...thread },
          };
        }
        return {
          ...result,
          [key]: { ...thread },
        };
      }, {});
      return _.orderBy(
        _.map(
          _.values(unique),
          (thread) => ({
            ...thread,
            replies: {
              comments: _.orderBy(
                thread.replies.comments,
                (reply) => new Date(reply.createdAt),
                ['asc'],
              ),
            },
          }),
        ),
        (thread) => new Date(thread.createdAt),
        ['desc'],
      );
    });
  }, [comments]);

  useEffect(() => {
    threads.forEach((thread) => {
      if (upvotes[thread.id] === undefined) {
        setUpvote(thread.id, thread.has_upvoted);
      }

      if (thread.replies) {
        thread.replies.comments.forEach((reply) => {
          if (upvotes[reply.id] === undefined) {
            setUpvote(reply.id, reply.has_upvoted);
          }
        });
      }
    });
  }, [threads]);

  const addThread = useAddThread(setThreads);

  return [threads, {
    update,
    addThread,
    loadMore,
    refetch,
    loading,
    error,
  }];
};
export default useThreadLoader;
