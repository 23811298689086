import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 1;
`;
const containerBorder = ({ dots, dotsSize, dotsColor }) => {
  if (!dots) return 'none';
  return `${dotsSize}px dashed ${dotsColor}`;
};
export const Container = styled.div`
  padding: 1em;
  border: ${containerBorder};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 2rem;
`;
