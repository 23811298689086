import React from 'react';
import { useTranslation } from 'react-i18next';
import MoodmeterResults from '../../../../components/Moodmeter/Results';
import * as Styled from '../MeterModule.styled';

const MoodmeterResultsScreen = ({
  onVoteAgain,
  value,
  color = '#0000ff',
  secondaryColor = '#ffffff',
  fontColor = '#ffffff',
}) => {
  const { t } = useTranslation();

  return (
    <Styled.Content>
      <MoodmeterResults
        value={value}
        color={color}
        secondaryColor={secondaryColor}
        fontColor={fontColor}
        dots
      />
      <Styled.ArrowButton onClick={onVoteAgain}>
        {t('modules.moodmeter.vote_again')}
      </Styled.ArrowButton>
    </Styled.Content>
  );
};
export default MoodmeterResultsScreen;
