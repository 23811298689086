import React, { useEffect } from 'react';
import { ModuleType } from '../../enums/module.enums';
import useShow from '../../hooks/useShow';
import CommentsModule from '../../pages/modules/CommentsModule/CommentsModule';
import MeterModule from '../../pages/modules/MeterModule/MeterModule';
import VoteModule from './VoteModule';
import ReactionModule from './ReactionModule';
import ProductLaunchModule from './ProductLaunchModule';
import WordCloudModule from './WordCloudModule/WordCloudModule';

const ModuleSwitch = ({ module, active }) => {
  switch (module.type) {
    case ModuleType.Comments:
    case ModuleType.QuestionsAndAnswers:
      return <CommentsModule module={module} active={active} />;
    case ModuleType.WordCloud:
      return <WordCloudModule module={module} active={active} />;
    case ModuleType.Vote:
      return <VoteModule module={module} active={active} />;
    case ModuleType.Reaction:
      return <ReactionModule module={module} active={active} />;
    case ModuleType.ProductLaunch:
      return <ProductLaunchModule module={module} active={active} />;
    case ModuleType.Moodmeter:
      return <MeterModule module={module} active={active} />;
    default:
      return <div>Something went wrong...</div>;
  }
};

const ModuleComponent = ({ match, module }) => {
  // we need this function from the custom hook
  const { updateActiveModule, currentModule } = useShow();

  // if this view is active (match.type === 'full') then we tell the event hook that and update navigation
  useEffect(() => {
    if (match.type === 'full' && currentModule && currentModule.id !== module.id) {
      updateActiveModule(module);
    }
  }, [match, module]);

  if (!currentModule) return null;

  const active = currentModule.id === module.id;
  return <ModuleSwitch module={module} active={active} />;
};

export default ModuleComponent;
