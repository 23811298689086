import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from '../../../../components/Moodmeter/Slider';
import * as Styled from '../MeterModule.styled';

const MoodmeterVoteScreen = ({
  onChange,
  onVote,
  onShowResults,
  value: initialValue,
  color = 'blue',
  secondaryColor = '#ffffff',
  fontColor = '#ffffff',
  showResultsButton = false,
  labels = ['High', 'Medium', 'Low'],
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(initialValue);
  const leftLabels = ['0%', '50%', '100%'];

  const handleChange = useCallback((result) => {
    setValue(result);
    if (typeof onChange === 'function') {
      onChange(result);
    }
  }, [onChange]);
  const handleSubmit = useCallback(
    () => onVote(value),
    [value, onVote],
  );

  return (
    <Styled.Content>
      <Slider
        value={value}
        onChange={handleChange}
        leftLabels={leftLabels}
        rightLabels={labels}
        color={color}
        secondaryColor={secondaryColor}
        fontColor={fontColor}
        showValue
      />
      {showResultsButton ? (
        <Styled.ArrowButton onClick={onShowResults}>
          {t('modules.moodmeter.results')}
        </Styled.ArrowButton>
      ) : (
        <Styled.ArrowButton onClick={handleSubmit} inverted color={color}>
          {t('modules.moodmeter.submit')}
        </Styled.ArrowButton>
      )}
    </Styled.Content>
  );
};
export default MoodmeterVoteScreen;
