import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import Loader from 'react-loader-spinner';
import { ModuleStatus } from '../../../../enums/module.enums';
import { zIndex } from '../../../../library/styleAbstracts/variables';
import { canVote } from '../../../../library/voteLimit';
import Option from './Option';
import VoteButtons from './VoteButtons';
import useShow from '../../../../hooks/useShow';
import VoteOptionsDisabled from '../VoteOptionsDisabled';

const FreeVoteLoader = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${zIndex.modal};
`;

// Also used for Vote type photo
const VoteTypeText = ({ isPhoto, moduleId, options, totalCount, onUpdate }) => {
  const [voteOptions, setVoteOptions] = useState(false);
  const [voteDisabledDisplay, setVoteDisabledDisplay] = useState(false);
  const [hasAnimation, setHasAnimation] = useState(true);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const optionClassList = ['option'];
  if (hasAnimation) {
    optionClassList.push('animated');
    optionClassList.push('lightSpeedIn');
    optionClassList.push('medium');
  }
  const { currentModule } = useShow();
  const isSubTypePhoto = isPhoto;
  const handleOptionClick = (event, option_id) => {
    event.stopPropagation();
    if (currentModule.status !== ModuleStatus.Live) {
      setVoteDisabledDisplay(option_id);
    } else if (!canVote(currentModule) && !currentModule.is_paid) {
      setVoteDisabledDisplay(option_id);
    } else {
      setVoteOptions(option_id);
    }
  };
  const handleClose = () => {
    setHasAnimation(false);
    setVoteOptions(false);
    onUpdate();
  };
  const renderOptions = () => (
    options.map((option, index) => (
      <div
        role="button"
        tabIndex="0"
        className={
          voteOptions && voteOptions === option.id ? 'option option-active' : optionClassList.join(' ')
        }
        style={{ animationDelay: `${index / 8}s` }}
        key={option.id}
        onKeyPress={(event) => handleOptionClick(event, option.id)}
        onClick={(event) => handleOptionClick(event, option.id)}
      >
        <Option
          isPhoto={isSubTypePhoto}
          moduleId={moduleId}
          optionData={option}
        />
        {voteOptions && voteOptions === option.id && (
          <VoteButtons
            optionData={option}
            handleClose={handleClose}
            setLoading={setLoading}
          />
        )}
        {voteDisabledDisplay && voteDisabledDisplay === option.id && (
          <VoteOptionsDisabled
            status={currentModule.status}
            setVoteDisabledDisplay={setVoteDisabledDisplay}
            optionName={option.title}
          />
        )}
      </div>
    ))
  );
  return (
    <>
      {(loading) && (
        <FreeVoteLoader>
          <Loader
            type="Grid"
            color="var(--color-primary)"
            height={100}
            width={100}
          />
        </FreeVoteLoader>
      )}
      <div>{renderOptions()}</div>
      {currentModule.show_total_count && !Number.isNaN(totalCount)
        ? <div className="text-center">{t('modules.vote.form.votes')}: {totalCount}</div>
        : <></>}
    </>
  );
};

export default VoteTypeText;
