import React, { useState } from 'react';

// eslint-disable-next-line
const noopSetState = (oldState) => {};

/**
 * @type {React.Context<[{},function(object):object]>}
 */
export const RootContext = React.createContext([{}, noopSetState]);

export default (props) => {
  const [state, setState] = useState({});
  const { children } = props;
  return (
    <RootContext.Provider value={[state, setState]}>
      {children}
    </RootContext.Provider>
  );
};
