import styled from 'styled-components/macro';

const containerHeight = ({ viewport }) => (
  `calc(${viewport.height}px - (${viewport.top}px + ${viewport.bottom}px))`
);
export const Container = styled.div`
  position: relative;
  background-color: #ebebeb;
  display: flex;
  flex-direction: column;
  height: ${containerHeight};
`;

const overflowHeight = ({ overflow }) => (
  typeof overflow === 'number'
    ? `${overflow}px`
    : overflow
);
export const CommentsOverflow = styled.div`
  min-height: ${overflowHeight};
`;

const primaryColor = ({ primaryColor: color = '#c0c0c0' }) => color;
export const CommentsWrapper = styled.div`
  overflow-y: scroll;
  flex-grow: 1;
  padding: 12px ;

  scrollbar-gutter: stable both-edges;
  scrollbar-width: thin;
  scrollbar-color: ${primaryColor} transparent;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${primaryColor};
    border-radius: 5px;
  }
`;

export const InputWrapper = styled.div`
  border-style: solid;
  border-color: #c0c0c0;
  border-width: 1px 0 0 0;
  padding: 8px 0 4px;
  background-color: #ebebeb;
  box-shadow: 0px -5px 15px 5px rgba(0,0,0,0.1);
`;

export const ReplyWrapper = styled.div`
  background-color: #ebebeb;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
  border-style: solid;
  border-color: #c0c0c0;
  border-width: 0 0 1px 0;
  padding: 0 12px 8px;
  margin-bottom: 8px;
  
  .reply-controls {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: .7em;

    button {
      cursor: pointer;
    }

    span {
      margin: 0 0 6px 6px;
    }

    button.reply-close {
      opacity: .7;
      border: none;
      text-decoration: none;
      cursor: pointer;
      
      &:hover { opacity: 1 }
    }
  }
`;

export const CommentsNotAllowed = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15%;
  text-align: center;
`;
