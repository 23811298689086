import styled from 'styled-components/macro';

const cssSize = (size) => (typeof size === 'number' ? `${size}px` : size);
const color = (props) => props.color;
const secondaryColor = (props) => props.secondaryColor;
const fontColor = (props) => props.fontColor;
const trackSize = (props) => cssSize(props.trackSize);
const thumbSize = (props) => cssSize(props.thumbSize);
export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  margin: 1.5rem 0;
  justify-content: center;
  font-family: inherit;
`;

export const Wrapper = styled.div`
  width: 48px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const labelsAlignment = ({ left, right }) => {
  if (left) return 'right';
  if (right) return 'left';
  return 'unset';
};
export const Labels = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  height: 100%;
  width: 32px;
  flex-grow: 1;
  color: ${fontColor};
  text-align: ${labelsAlignment};
`;

export const SliderTrack = styled.div`
  flex-grow: 1;
  width: ${trackSize};
  background: linear-gradient(to top, transparent 45%, ${secondaryColor} 45%),
  linear-gradient(to top, ${color}, ${color});
  background-size: 2px 6%, 2px 100%;
  background-position-y: top;
`;

export const SliderThumbWrapper = styled.div`
  position: relative;
  padding: 3px;
  outline: none;
`;

export const SliderThumb = styled.div`
  height: ${thumbSize};
  width: ${thumbSize};
  background-color: ${color};
  border-radius: 50%;
  border: 2px solid ${secondaryColor};
`;

const thumbExtraOpacity = ({ visible }) => {
  if (visible) return 1;
  return 0;
};
export const SliderThumbExtra = styled.div`
  position: absolute;
  opacity: ${thumbExtraOpacity};
  transition: opacity 150ms ease-in-out;
  bottom: 120%;
  left: 50%;
  margin-right: 1em;
  transform: translateX(-50%);
  font-weight: bold;
  color: ${color};
  background-color: ${secondaryColor};
  border-radius: 0 10px 0 10px;
  border: 1px solid ${color};
  padding: .6em 1em;
`;
