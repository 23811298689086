import React from 'react';
import { useTranslation } from 'react-i18next';
import VotemoPage from './votemo';
import TermsPage from './terms';
import { CPage } from './styled/StyledComponentsPages';

const Page = (params) => {
  // Using translations for different pages for now. We might request the content at some point
  const { t } = useTranslation();
  const { pageType } = params;
  const pageClassList = ['page', pageType];
  if (pageType === 'votemo') {
    return <VotemoPage />;
  }
  if (pageType === 'terms') {
    return <TermsPage />;
  }
  return (
    <CPage className={pageClassList.join(' ')}>
      <div className="page-inner">
        <div className="page__title">
          <h1>{t(`pages.${pageType}.title`)}</h1>
        </div>
        <div className="page__content">
          <p>{t(`pages.${pageType}.content_p1`)}</p>
          {pageType !== 'votemo' && (
            <p>{t(`pages.${pageType}.content_p2`)}</p>
          )}
        </div>
      </div>
    </CPage>
  );
};

export default Page;
