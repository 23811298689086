// https://stackoverflow.com/a/11868398
const getContrastYIQ = (hc) => {
  const [r, g, b] = [0, 2, 4].map((p) => parseInt(hc.substr(p, 2), 16));
  const isBlack = ((r * 299) + (g * 587) + (b * 114)) / 1000 >= 128;
  if (isBlack) {
    return '000';
  }
  return 'fff';
};

export default getContrastYIQ;
