import styled, { css } from 'styled-components/macro';

const animateIconOffset = ({ offset }) => `${offset}px`;
const animations = css`
  @keyframes animate-icon {
    from { background-position: 0; }
    to { background-position: ${animateIconOffset}; }
  }
`;

export const Wrapper = styled.div`
  ${animations};
  
  font-size: 4rem;
`;

const spriteImage = ({ image }) => `url(${image})`;
const spriteHeight = ({ height }) => `${height}px`;
const spriteWidth = ({ width }) => `${width}px`;
const spriteSteps = ({ steps }) => `steps(${steps})`;
export const Sprite = styled.span`
  width: ${spriteWidth};
  height: ${spriteHeight};
  display: block;
  animation: animate-icon 2s ${spriteSteps} infinite;
  animation-delay: 500ms;
  animation-iteration-count: 4;
  transform: scale(1);
  margin: 0 auto;
  background-image: ${spriteImage};
`;
