import React from 'react';
import styled from 'styled-components/macro';
import { ModuleType } from '../../enums/module.enums';
import useShow from '../../hooks/useShow';
import useViewport from '../../hooks/viewport';

const WatermarkWrapper = styled.span`
  position: absolute;
  //bottom: 24px;
  bottom: ${({ viewport }) => viewport.bottom + 8}px;
  right: ${({ viewport }) => viewport.right}px;
  color: #808080;
  font-size: .6rem;
  
  a {
    color: #808080;
  }
`;

const WaterMark = () => {
  const { currentModule, whitelabel } = useShow();
  const viewport = useViewport();

  if (whitelabel || currentModule.type === ModuleType.ProductLaunch) {
    return '';
  }

  return (
    <WatermarkWrapper viewport={viewport}>
      {/* eslint-disable-next-line react/jsx-no-target-blank */}
      Powered by <a href="https://www.votemo.eu" target="_blank">Votemo</a>
    </WatermarkWrapper>
  );
};

export default WaterMark;
