import React, { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ThreadList from '../../../components/ThreadList';
import useShowColors from '../../../hooks/useShowColors';
import useViewport from '../../../hooks/viewport';
import * as Styled from './CommentsModule.styled';
import useCommentUpvote from './hooks/useCommentUpvote';

const CommentsModuleContent = ({
  module = null,
  threads = null,
  loading = false,
  onReply = () => {},
  onScroll = () => {},
  containerRef = undefined,
}) => {
  const { t } = useTranslation();
  const viewport = useViewport();
  const [upvotes, addUpvote, removeUpvote] = useCommentUpvote();

  /** @type {React.MutableRefObject<HTMLDivElement>} */
  const commentsOverflowRef = useRef();
  const [primaryColor] = useShowColors();

  const handleAddUpvote = useCallback(
    (thread) => addUpvote(thread.id),
    [addUpvote],
  );

  const handleRemoveUpvote = useCallback(
    (thread) => removeUpvote(thread.id),
    [removeUpvote],
  );

  const [orderBy, order] = useMemo(() => {
    switch (module.type) {
      case 'questions_answers':
        return ['total_upvotes', 'desc'];
      case 'comment':
        return ['createdAt', 'desc'];
      default:
        return ['createdAt', 'desc'];
    }
  }, [module]);

  const enableUpvotes = useMemo(
    () => (
      module.type === 'questions_answers'
      || module.type === 'comment'
    ),
    [module],
  );


  const enableReplies = useMemo(
    () => module.type === 'comment',
    [module],
  );

  // TODO: Cleanup
  // Calculate whether to resize the overflow container
  // to at least 100% height to enable scrolling
  const imageHeight = useMemo(() => {
    if (!module || !module.image) return 0;
    const { width } = viewport;
    return (width / 21) * 9;
  }, [module, viewport]);
  const overflowHeight = useMemo(() => {
    if (!module || !module.image) return 'unset';
    if (!commentsOverflowRef.current) return 'unset';
    const { clientHeight } = commentsOverflowRef.current;
    const availableRoom = viewport.height - imageHeight;
    if (availableRoom >= clientHeight) {
      return clientHeight;
    }
    return viewport.height;
  }, [commentsOverflowRef.current, module, threads, viewport]);

  if (loading && (!threads || threads.length === 0)) {
    return (
      <Styled.CommentsWrapper primaryColor={primaryColor}>
        <Styled.CommentsNotAllowed>
          Loading...
        </Styled.CommentsNotAllowed>
      </Styled.CommentsWrapper>
    );
  }

  if (threads.length === 0 || !module.show_all_comments) {
    return (
      <Styled.CommentsWrapper primaryColor={primaryColor}>
        <Styled.CommentsNotAllowed>
          {
            module.type === 'comment'
              ? t('modules.comment.send_thoughts_questions')
              : t('modules.comment.send_questions')
          }
        </Styled.CommentsNotAllowed>
      </Styled.CommentsWrapper>
    );
  }

  return (
    <Styled.CommentsWrapper
      ref={containerRef}
      onScroll={onScroll}
      primaryColor={primaryColor}
    >
      <Styled.CommentsOverflow
        ref={commentsOverflowRef}
        overflow={overflowHeight}
      >
        <ThreadList
          moderatedAuthorColor={primaryColor}
          upvoteColor={primaryColor}
          onAddUpvote={handleAddUpvote}
          onRemoveUpvote={handleRemoveUpvote}
          onReply={onReply}
          upvotes={upvotes}
          threads={threads}
          orderBy={orderBy}
          order={order}
          showTotalUpvotes={enableUpvotes}
          enableUpvotes={enableUpvotes}
          enableReplies={enableReplies}
        />
      </Styled.CommentsOverflow>
    </Styled.CommentsWrapper>
  );
};
export default CommentsModuleContent;
