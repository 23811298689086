import React from 'react';
import useViewport from '../../hooks/viewport';
import * as Styled from './ScrollingHeaderImage.styled';

const ScrollingHeaderImage = ({ image, scrollTop = 0 }) => {
  const viewport = useViewport();
  if (!image) return null;

  return (
    <Styled.ImageWrapper viewport={viewport} scrollTop={scrollTop}>
      <Styled.Image src={`${process.env.REACT_APP_STATIC_URL}/${image}`} alt="" />
    </Styled.ImageWrapper>
  );
};

export default ScrollingHeaderImage;
