import styled from 'styled-components/macro';

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 64px;
  gap: 8px;
  box-shadow: 0 5px 15px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
`;
export const HeadingTitle = styled.h1`
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;
export const HeadingSubtitle = styled.h2`
  margin: 0;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
`;
