import { useMutation } from '@apollo/client';
import { useCallback, useContext } from 'react';
import { UpvoteContext } from '../../../../context/upvote-context';
import {
  COMMENT_UPVOTE_ADD, COMMENT_UPVOTE_REMOVE,
} from '../../../../graphql/mutations/upvoteComment';
import useViewer from '../../../../hooks/viewers';

const useCommentUpvote = () => {
  const [viewer] = useViewer();
  const [upvotes, setUpvote] = useContext(UpvoteContext);
  const [upvoteAdd] = useMutation(COMMENT_UPVOTE_ADD);
  const [upvoteRemove] = useMutation(COMMENT_UPVOTE_REMOVE);

  const handleAddUpvote = useCallback(async (threadId) => {
    if (!viewer) {
      return;
    }
    await upvoteAdd({
      variables: {
        comment_id: threadId, payload: { uid: viewer.id },
      },
    });
    setUpvote(threadId, true);
  }, [viewer, upvoteAdd, setUpvote]);
  const handleRemoveUpvote = useCallback(async (threadId) => {
    if (!viewer) {
      return;
    }
    await upvoteRemove({
      variables: {
        comment_id: threadId, payload: { uid: viewer.id },
      },
    });
    setUpvote(threadId, false);
  }, [viewer, upvoteRemove, setUpvote]);

  return [upvotes, handleAddUpvote, handleRemoveUpvote];
};

export default useCommentUpvote;
