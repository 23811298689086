import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import COMMENT_VALIDATE from '../../graphql/mutations/validateComment';

const useValidateComment = () => {
  const [isValid, setIsValid] = useState(false);
  const [validateComment, { data, loading, error }] = useMutation(COMMENT_VALIDATE);

  const validate = async (payload) => {
    const response = await validateComment({ variables: { payload } });

    if (response.data) {
      const { success } = response.data.validateComment;
      return success;
    }

    return false;
  };

  useEffect(() => {
    if (loading || !data || error) {
      setIsValid(false);
      return;
    }

    const { success } = data.validateComment;
    setIsValid(success);
  }, [loading, data, error]);

  return [validate, { isValid, loading, error }];
};

export default useValidateComment;
