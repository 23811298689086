import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ThreadWrapper = styled.div`
  margin-bottom: 6px;
`;

export const ReplyWrapper = styled.div`
  margin-top: 6px;
`;
