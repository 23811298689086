import { gql } from '@apollo/client';

const GET_COMMENT_UPDATES = gql`
    query ($module_id: Int!, $cursor: DateTime, $uid: String, $order: CommentsOrder) {
        commentUpdates(module_id: $module_id, uid: $uid, cursor: $cursor, order: $order) {
            cursor
            new {
                id
                parent_id
                uid
                author
                text
                is_hidden
                total_upvotes
                has_upvoted
                createdAt
                is_moderated
            }
            updated {
                id
                parent_id
                uid
                author
                text
                is_hidden
                total_upvotes
                has_upvoted
                createdAt
                is_moderated
            }
        }
    }
`;

export default GET_COMMENT_UPDATES;
