import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { CPage } from './styled/StyledComponentsPages';
import useShow from '../../hooks/useShow';

const PageTitle = styled.h1``;
const PageContent = styled.p``;

const usePageTitle = (type) => {
  const { showInfo, episodeInfo } = useShow();
  return useMemo(() => {
    switch (type) {
      case 'show':
        return showInfo.name;
      case 'episode':
        return episodeInfo.name;
      default:
        return null;
    }
  }, [type, showInfo.name, episodeInfo.name]);
};

const usePageContent = (type) => {
  const { showInfo, episodeInfo } = useShow();
  return useMemo(() => {
    switch (type) {
      case 'show':
        return showInfo.description;
      case 'episode':
        return episodeInfo.description;
      default:
        return null;
    }
  }, [type, showInfo.description, episodeInfo.description]);
};

const ContentPage = ({ type }) => {
  const title = usePageTitle(type);
  const content = usePageContent(type);
  return (
    <CPage>
      <header>
        <PageTitle>{ title }</PageTitle>
      </header>
      <PageContent>{ content }</PageContent>
    </CPage>
  );
};

export default ContentPage;
