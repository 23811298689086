import { gql } from '@apollo/client';

const CREATE_PRODUCT_LAUNCH_VOTE = gql`
    mutation createProductLaunchVote($module_id: Int!, $pl_type: String!, $tracking_id: String){
        createProductLaunchVote(module_id: $module_id, pl_type: $pl_type, tracking_id: $tracking_id){
            success
        }
    }
`;

export default CREATE_PRODUCT_LAUNCH_VOTE;
