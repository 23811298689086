import styled from 'styled-components/macro';
import { sizes } from '../../../library/styleAbstracts/variables';

const { headerHeight } = sizes;

export const CProductLaunchModule = styled.div`
  background-color: var(--color-secondary);
  display: flex;
  flex-direction: column;
  height: ${(props) => `calc(${props.height}px - (${props.showHeader ? headerHeight : '0px'}));`};
  position: relative;
  overflow: hidden;
`;

export const CProductLaunchButton = styled.button`
  border: 6px solid white;
  border-width: ${(props) => (props.buttonBorderWidth ? `${props.buttonBorderWidth}px;` : '6px;')};
  padding: 1rem;
  position: absolute;
  width: ${(props) => (props.buttonWidth ? `${props.buttonWidth}px;` : '105px;')};
  height: ${(props) => (props.buttonWidth ? `${props.buttonWidth}px;` : '105px;')};
  border-radius: 50%;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  font-size: 0.6rem;
  color: white;
  font-weight: bold;
  background-color: transparent;
  &:after {
    content: '';
    width: ${(props) => (props.buttonWidth ? `${props.buttonWidth}px;` : '105px;')};
    height: ${(props) => (props.buttonWidth ? `${props.buttonWidth}px;` : '105px;')};
    background: transparent;
    display: block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    border-radius: 50%;
    animation: glowBox 1.5s infinite alternate;
    padding: 1rem;
    border: 6px solid white;
    border-width: ${(props) => (props.buttonBorderWidth ? `${props.buttonBorderWidth}px;` : '6px;')};
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
`;

export const CProductLaunchButtonText = styled.span`
  animation: glowText 1.5s infinite alternate;
  color: white;
  font-size: ${(props) => (props.buttonFontSize ? `${props.buttonFontSize}px` : '0.6rem;')};
`;

export const CProductLaunchVideoContainer = styled.div`
  max-width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
`;

export const CProductLaunchVideo = styled.video`
  width: 100%;
  height: auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 50%;
  transform: translateY(-50%);
`;

export const CLimitReached = styled.div`
  color: white;
  animation: glowText 1.5s infinite alternate;
  font-weight: bold;
  align-self: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;
