import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import useWelcome from '../../hooks/useWelcome';
import useShow from '../../hooks/useShow';

export default ({ component: Component, ...rest }) => {
  const { isTermsAccepted } = useWelcome();
  const { episodeInfo } = useShow();
  if (episodeInfo) {
    if (!episodeInfo.show_terms) {
      return (
        <Route
          {...rest}
          render={(props) => (
            <Component {...props} />
          )}
        />
      );
    }
    return (
      <Route
        {...rest}
        render={(props) => (
          // @todo: Fix this
          // eslint-disable-next-line no-nested-ternary
          isTermsAccepted()
            ? <Component {...props} />
            : episodeInfo.terms_location === 'welcome' ? <Redirect to="/welcome" /> : <Component {...props} />
        )}
      />
    );
  }
  return <div>Loading show...</div>;
};
