import React, { useEffect, useState } from 'react';
import {
  Route, Switch, Redirect,
  useHistory,
} from 'react-router-dom';
import { ModuleType } from '../../enums/module.enums';
import useShow from '../../hooks/useShow';
import { trackPageView } from '../../library/gaHelper';

import Header from '../../components/Header';
import Content from '../../components/Content';
import Footer from '../../components/Footer';
import Page from '../../components/Page';
import ContentPage from '../../components/Page/ContentPage';
import WaitingPage from '../../components/Page/waitingPage';

const App = (params) => {
  const {
    modules, currentModule, showHeader,
  } = useShow();
  const { location } = params;
  const [displayHeader, setDisplayHeader] = useState(showHeader);
  const history = useHistory();
  const regularPages = ['votemo', 'terms'];
  const contentPages = ['show-info', 'episode-info'];
  useEffect(() => {
    trackPageView(location.pathname);
  }, [location]);
  useEffect(() => {
    if (showHeader !== displayHeader) {
      setDisplayHeader(showHeader);
    }
  }, [showHeader]);
  useEffect(() => {
    // Redirect to first available module if current module is not available
    const { pathname } = location;
    const path = pathname.substring(1, pathname.length);
    if (regularPages.includes(path)) return;
    if (contentPages.includes(path)) return;
    const current = parseInt(path, 10);
    const module = modules.find((mod) => mod.id === current);
    if (!module && modules.length) {
      history.push(`/${modules[0].id}`);
    }
  }, [modules, location]);

  // on 15/11/2023, there was a Request from Tõnis to show the header on productLaunch banners
  /*  useEffect(() => {
      if (showHeader && currentModule.type === ModuleType.ProductLaunch) {
        setDisplayHeader(true);
      }
      if (showHeader && currentModule.type !== ModuleType.ProductLaunch) {
        setDisplayHeader(true);
      }
    }, [currentModule, showHeader]); */
  const isDarkTheme = () => {
    // Might need to rethink this, when urls get more complex
    const pathNameWithoutTrailingSlash = location.pathname.replace('/', '');
    return typeof currentModule !== 'undefined'
      && currentModule.type === ModuleType.Comments
      && regularPages.indexOf(pathNameWithoutTrailingSlash) === -1;
  };
  const renderRegularPagesRoutes = () => regularPages.map((page) => (
    <Route path={`/${page}`} key={page}>
      <Page pageType={page} />
    </Route>
  ));

  if (modules && location.pathname === '/' && modules.length > 0) {
    return <Redirect to={`/${modules[0].id}`} />;
  }

  const appClassNames = ['app'];

  if (isDarkTheme()) {
    appClassNames.push('dark');
  }

  return (
    <div className={appClassNames.join(' ')}>
      {modules.length > 0 ? (
        <>
          {displayHeader && <Header />}
          <Switch>
            {renderRegularPagesRoutes()}
            <Route path="/show-info">
              <ContentPage type="show" />
            </Route>
            <Route path="/episode-info">
              <ContentPage type="episode" />
            </Route>
            <>
              <Content />
              <Footer />
            </>
          </Switch>
        </>
      ) : (
        <WaitingPage />
      )}
    </div>
  );
};

export default App;
