import React from 'react';
import { useTranslation } from 'react-i18next';
import SuccessImage from '../../../assets/img/celebrate_2.png';
import Sprite from '../../Sprite/Sprite';
import * as Styled from './SuccessDialog.styled';

const SuccessDialog = ({
  image = SuccessImage,
  imageOffset = -4000,
  imageWidth = 80,
  imageHeight = 80,
  imageSteps = 50,
  color = 'blue',
  onClickNext,
  onClickResults,
  hasNextModule = false,
}) => {
  const { t } = useTranslation();

  return (
    <Styled.Container>
      <Sprite
        imageUrl={image}
        offset={imageOffset}
        width={imageWidth}
        height={imageHeight}
        steps={imageSteps}
      />
      <Styled.Title color={color}>
        {t('modules.vote.labels.success')}
      </Styled.Title>
      {hasNextModule && (
        <Styled.Button color={color} onClick={onClickNext} primary>
          {t('modules.vote.buttons.success_next')}
        </Styled.Button>
      )}
      <Styled.Button color={color} onClick={onClickResults}>
        {t('modules.moodmeter.results')}
      </Styled.Button>
    </Styled.Container>
  );
};
export default SuccessDialog;
