import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { CPage } from './styled/StyledComponentsPages';
import useShow from '../../hooks/useShow';

const VotemoPage = () => {
  // Using translations for different pages for now. We might request the content at some point
  const { t } = useTranslation();
  const { language } = useShow();

  const pageClassList = ['page', 'votemo'];
  return (
    <CPage className={pageClassList.join(' ')}>
      <div className="page-inner">
        <div className="page__header__votemo">
          <h1>{t('pages.votemo.title')}</h1>
        </div>
        <div className="page__content__votemo">
          { language === 'et' ? (
            <>
              <p>{t('pages.votemo.content_p1')}</p>
              <p>{t('pages.votemo.content_p2')}</p>
              <p>{t('pages.votemo.content_p3')}</p>
              <p>
                <Trans
                  i18nKey="pages.votemo.content_p4"
                  components={[
                    <a href="https://votemo.com">votemo.com</a>,
                    <a href="mailto:hello@votemo.eu">hello@votemo.eu</a>
                  ]}
                />
              </p>
            </>

          ) : (
            <>
              <p>{t('pages.votemo.content_p1')}</p>
              <p>{t('pages.votemo.content_p2')}</p>
              <p>
                <Trans
                  i18nKey="pages.votemo.content_p3"
                  components={[
                    <a href="https://votemo.com">votemo.com</a>,
                    <a href="mailto:hello@votemo.eu">hello@votemo.eu</a>
                  ]}
                />
              </p>
            </>
          )}
        </div>
      </div>
    </CPage>
  );
};

export default VotemoPage;
