import styled from 'styled-components/macro';

export const AuthorWrapper = styled.div`
  position: relative;
  width: 100%;
  border-bottom: 1px solid #505151;

  ${({ hasError }) => hasError && `
    border-color: $danger;

    .label__text {
      color: $danger;
    }
  `}
`;
const authorLabelBackground = ({ isActive }) => (
  isActive ? 'rgba(255, 255, 255, 0.5)' : 'transparent'
);
export const AuthorLabelText = styled.span`
  position: absolute;
  display: block;
  left: 0;
  pointer-events: none;
  transition: all 0.15s ease-in-out;
  bottom: 0;
  font-weight: bold;
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
  background-color: ${authorLabelBackground};
  padding: 4px 8px 0 1rem;
  border-top-right-radius: 5px;
  backdrop-filter: blur(15px);

  ${({ isActive }) => isActive && `
    bottom: 100%;
    padding-left: 0;
  `}
  ${({ hasError }) => hasError && `
    color: #cd0000;
  `}
`;
export const AuthorInput = styled.input`
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  background-color: #ebebeb;
  width: 100%;
  display: block;
  padding: 0.25rem 1rem 0;
  font-size: 0.75rem;
  border: none;

  &:focus {
    outline: 0;
  }
`;
export const AuthorLengthContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0.2rem;
  font-size: 0.75rem;
`;
