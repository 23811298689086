import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import VOTES from '../../../graphql/queries/votes';
import useShow from '../../../hooks/useShow';
import { canVote } from '../../../library/voteLimit';
import VoteResults from './VoteResults';
import VotingScreen from './VotingScreen';

const VoteModule = ({ module, active }) => {
  const location = useLocation();
  const { showInfo } = useShow();
  const {
    data, refetch, startPolling, stopPolling,
  } = useQuery(VOTES, {
    variables: {
      module_id: module.id,
    },
  });

  useEffect(() => {
    let isPolling = false;

    if (showInfo && active) {
      startPolling(showInfo.poll_interval);
      isPolling = true;
    }

    return () => {
      if (isPolling) {
        stopPolling();
      }
    };
  }, [showInfo, active]);

  let arrivedFromVoteButton = false;
  if (location.state && typeof location.state !== 'undefined' && location.state.view === 'results') {
    arrivedFromVoteButton = true;
  }

  useEffect(() => {
    // Update poll results right after submitting your own vote.
    if (arrivedFromVoteButton
      && Object.prototype.hasOwnProperty.call(location.state, 'fetchVotes')
      && location.state.fetchVotes) {
      refetch();
    }
  }, [arrivedFromVoteButton]);

  if (arrivedFromVoteButton && data) {
    return (
      <VoteResults
        data={{
          results: data.votes.results,
          currentModule: module,
        }}
      />
    );
  }
  if (module.status === 'ENDED' && module.show_results && data && data.votes) {
    return (
      <VoteResults
        data={{
          view: 'results',
          results: data.votes.results,
          currentModule: module,
        }}
      />
    );
  }

  if (!canVote(module) && module.show_results && data && data.votes) {
    return (
      <VoteResults
        data={{
          view: 'results',
          results: data.votes.results,
          currentModule: module,
        }}
      />
    );
  }

  if (data) {
    return (
      <VotingScreen
        totalCount={data.votes.results.totalCount}
        module={module}
        onUpdate={refetch}
      />
    );
  }
  return (
    <VotingScreen
      module={module}
      onUpdate={refetch}
    />
  );
};

export default VoteModule;
