export const canVote = (currentModule) => {
  const voteData = localStorage.getItem('voteCount');
  const voteDataParsed = JSON.parse(voteData);
  if (currentModule.vote_limit === '' || !currentModule.vote_limit || currentModule.vote_limit === 0) {
    // Return true early, there is no limit
    return true;
  }
  if (voteData === null) {
    // User has not voted yet
    return true;
  }
  // eslint-disable-next-line no-restricted-syntax,no-unused-vars
  for (const module of voteDataParsed) {
    if (module.module_id === currentModule.id) {
      if (module.voteCount >= currentModule.vote_limit) {
        return false;
      }
    }
  }
  return true;
};

export const incrementVoteCount = (currentModule) => {
  let voteData = localStorage.getItem('voteCount');
  if (voteData === null) {
    // User has not voted yet
    voteData = [
      {
        module_id: currentModule.id,
        voteCount: 1,
      },
    ];
    localStorage.setItem('voteCount', JSON.stringify(voteData));
    return true;
  }
  const voteDataParsed = JSON.parse(voteData);
  // eslint-disable-next-line no-restricted-syntax,no-unused-vars
  for (const module of voteDataParsed) {
    if (module.module_id === currentModule.id) {
      module.voteCount += 1;
      localStorage.setItem('voteCount', JSON.stringify(voteDataParsed));
      return true;
    }
  }
  const moduleVoteData = {
    module_id: currentModule.id,
    voteCount: 1,
  };
  voteDataParsed.push(moduleVoteData);
  localStorage.setItem('voteCount', JSON.stringify(voteDataParsed));

  return true;
};
