import { useState } from 'react';

/**
 * @typedef { function(): void } ToggleFn
 * @typedef { ref: React.MutableRefObject<HTMLElement>, focus: ToggleFn, blur: ToggleFn } ActiveState
 *
 * Hook for tracking the active state of a component (active or inactive).
 * Returns functions for tracking blur and focus events, as well as the current active state
 * @param { undefined | boolean } keepActive
 * @return {[ boolean, ActiveState ]}
 */
const useActiveState = (keepActive = false) => {
  const [active, setActive] = useState(false);

  const focus = () => setActive(true);
  const blur = () => {
    if (!keepActive) {
      setActive(false);
    }
  };

  return [active, { focus, blur }];
};

export default useActiveState;
