import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useShow from '../../../../hooks/useShow';
import SuccessImage from '../../../../assets/img/celebrate_2.png';

const SuccessfulVote = () => {
  const { next: nextModuleId, currentModule } = useShow();
  const history = useHistory();
  const { t } = useTranslation();

  const handleClickNext = useCallback(() => {
    if (nextModuleId < 0) return;

    history.push(`/${nextModuleId}`);
  }, [nextModuleId]);

  const successIcon = useMemo(
    () => ({ backgroundImage: `url(${SuccessImage})` }),
    [],
  );

  return (
    <>
      <div className="modal__header">
        <div className="modal__title">
          <div className="modal__icon animated bounceIn">
            <span className="modal__icon-success" style={successIcon} />
          </div>
        </div>
      </div>
      <div className="modal__content" style={{ textAlign: 'center' }}>
        <h2>{t('modules.vote.labels.success')}</h2>
      </div>
      <div className="modal__actions">
        <button
          onClick={handleClickNext}
          type="button"
          className="actions__confirm"
        >
          {nextModuleId !== currentModule.id
            ? t('modules.vote.buttons.success_next')
            : t('modules.vote.buttons.done')}
        </button>
      </div>
    </>
  );
};

export default SuccessfulVote;
