import { CircularProgressBar } from '@tomik23/react-circular-progress-bar';
import React, { useMemo } from 'react';
import * as Styled from './MoodmeterResults.styled';

const MoodmeterResults = ({
  value,
  color = '#002EFE',
  secondaryColor = 'white',
  fontColor = 'white',
  dots = false,
  dotsColor = secondaryColor,
  dotsSize = 3,
}) => {
  const style = useMemo(
    () => ({ color, secondaryColor, fontColor }),
    [color, secondaryColor, fontColor],
  );
  return (
    <Styled.Wrapper>
      <Styled.Container
        dots={dots}
        dotsColor={dotsColor}
        dotsSize={dotsSize}
        {...style}
      >
        <CircularProgressBar
          id={0}
          percent={value}
          colorCircle={color}
          colorSlice={secondaryColor}
          fontColor={fontColor}
          fontWeight={700}
          size="100%"
          round
        />
      </Styled.Container>
    </Styled.Wrapper>
  );
};
export default MoodmeterResults;
