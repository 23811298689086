import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { canVote } from '../../../library/voteLimit';
import ModuleHeader from '../../ModuleHeader/ModuleHeader';
import ScrollingHeaderImage from '../../ScrollingHeaderImage';
import OptionResult from './OptionResult';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

const VoteResults = ({ data }) => {
  const { currentModule, results } = data;
  const [redirect, setRedirect] = useState(false);
  const { t } = useTranslation();
  const { height } = useWindowDimensions();

  const normalizedResults = useMemo(() => (currentModule.options.map((option) => ({
    ...results.options.find((resultOption) => (resultOption.id === option.id) && resultOption),
    ...option,
  }))
  ), [currentModule.options, results.options]);

  if (redirect) {
    return <Redirect to={`/${currentModule.id}`} />;
  }
  return (
    <div className="vote-results" style={{ height: `calc(${height}px - (6.5rem))` }}>
      <ScrollingHeaderImage image={currentModule.image} />
      <ModuleHeader module={currentModule} />
      <PerfectScrollBar
        options={{
          wheelSpeed: 0.5,
          swipeEasing: false,
          suppressScrollX: true,
          wheelPropagation: false,
          scrollYMarginOffset: 10,
        }}
      >
        {normalizedResults && (
          <ul className="vote-results__content animated fadeInRight">
            {normalizedResults.map((result, index) => (
              <OptionResult
                key={result.id}
                index={index}
                data={result}
                total={results.totalWeight}
              />
            ))}
          </ul>
        )}
        {currentModule.show_total_count
          ? <div className="text-center">{t('modules.vote.form.votes')}: {results.totalWeight}</div>
          : <></>}
      </PerfectScrollBar>
      <footer className="vote-results__footer">
        {currentModule.status !== 'ENDED' && canVote(currentModule) && (
          <button
            type="button"
            className="animated fadeInUp delay-05 btn vote-results__cta"
            onClick={() => setRedirect(true)}
          >
            {t('modules.vote.buttons.vote_again')}
          </button>
        )}
        {currentModule.status === 'ENDED' && (
          <h4 style={{ marginBottom: '3.5rem', textAlign: 'center' }}>
            {t('modules.vote.ended_title')}
          </h4>
        )}
      </footer>
    </div>
  );
};

export default VoteResults;
