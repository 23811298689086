import { ModuleStatus } from '../enums/module.enums';
import i18n from '../i18n';

const getModuleTitles = (module) => {
  const { status, title, subtitle } = module;

  let moduleTitle;
  let moduleSubtitle;
  switch (status) {
    case ModuleStatus.NotStarted:
      moduleTitle = i18n.t('modules.vote.not_started_title');
      moduleSubtitle = i18n.t('modules.vote.not_started_subtitle');
      if (module.subtitle_not_started) {
        moduleSubtitle = module.subtitle_not_started;
      }
      break;
    case ModuleStatus.Ended:
      moduleTitle = i18n.t('modules.vote.ended_title');
      moduleSubtitle = '';
      if (module.subtitle_ended) {
        moduleSubtitle = module.subtitle_ended;
      }
      break;
    case ModuleStatus.Live:
    default:
      moduleTitle = title;
      moduleSubtitle = subtitle;
      break;
  }

  return {
    moduleTitle,
    moduleSubtitle,
  };
};

export default getModuleTitles;
