import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 12px;
`;
export const AuthorWrapper = styled.div`
  position: relative;
  width: 100%;
  background-color: #ebebeb;
  border-bottom: 1px solid #505151;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-family: Montserrat, sans-serif;
  transition: all 150ms ease-in-out;
  margin-bottom: 6px;
  padding-top: 24px;

  ${({ hasError }) => hasError && `
    border-color: $danger;

    .label__text {
      color: $danger;
    }
  `}
`;
export const AuthorLabel = styled.div`
  position: absolute;
  pointer-events: none;
  transition: all 150ms ease-in-out;
  font-weight: bold;
  font-size: 0.75rem;
  padding: 4px;
  border-top-right-radius: 5px;
  top: 4px;
  left: 4px;

  ${({ hasError }) => hasError && `
    color: #cd0000;
  `}
`;
export const AuthorInput = styled.input`
  background-color: #ebebeb;
  width: 100%;
  display: block;
  padding: 4px 12px;
  font-size: 12px;
  border: none;

  &:focus {
    outline: 0;
  }
`;
export const AuthorLength = styled.div`
  font-size: 0.75rem;
`;
