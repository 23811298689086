import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import FermiSuccessImage from '../../../assets/img/fermi_success.png';
import ModuleContainer from '../../../components/ModuleContainer/ModuleContainer';
import SuccessDialog from '../../../components/Moodmeter/SuccessDialog/SuccessDialog';

import { ModalContext } from '../../../context/modal-context';
import CREATE_METER_VOTE from '../../../graphql/mutations/createMeterVote';
import GET_METER_VOTE_RESULTS from '../../../graphql/queries/getMeterVoteResults';
import useShow from '../../../hooks/useShow';
import useShowColors from '../../../hooks/useShowColors';

import useUploadedFile from '../../../hooks/useUploadedFile';
import useViewer from '../../../hooks/viewers';
import useViewport from '../../../hooks/viewport';
import * as Styled from './MeterModule.styled';
import MoodmeterResultsScreen from './screens/MoodmeterResultsScreen';
import MoodmeterVoteScreen from './screens/MoodmeterVoteScreen';

const MeterModule = ({ module }) => {
  const { showInfo, currentModule, next: nextModuleId } = useShow();
  const viewport = useViewport();
  const [viewer] = useViewer();
  const history = useHistory();
  const [, secondaryColor] = useShowColors();

  const [loading, setLoading] = useState(true);
  const [hasChanged, setHasChanged] = useState(false);
  const [savedValue, setSavedValue] = useState(null);
  const [currentValue, setCurrentValue] = useState(null);
  const [result, setResult] = useState(0);
  const [hasVoted, setHasVoted] = useState(false);

  const { showModal, closeModal } = useContext(ModalContext);
  const [loadResults, { data: resultsData }] = useLazyQuery(GET_METER_VOTE_RESULTS, {
    fetchPolicy: 'no-cache',
  });
  const [createVote] = useMutation(CREATE_METER_VOTE);

  const { title, subtitle, subtitle_results, moodmeter_labels } = module;
  const banner = useUploadedFile(module.image);

  useEffect(() => {
    if (!viewer) return;

    const uid = viewer.id;
    loadResults({
      variables: { module_id: module.id, uid },
    });
  }, [viewer, loadResults]);

  useEffect(
    () => {
      if (!resultsData || !resultsData.meterVoteResults) return;
      const data = resultsData.meterVoteResults;

      if (currentValue === null) {
        if (data.value !== null) {
          setHasChanged(false);
          setCurrentValue(data.value);
          setHasVoted(true);
        } else {
          setCurrentValue(50);
          setHasChanged(true);
        }
        setSavedValue(data.value);
      }
      setResult(data.result);
      setLoading(false);
    },
    [
      resultsData, currentValue,
      setCurrentValue, setSavedValue,
      setHasChanged, setHasVoted,
      setResult, setLoading,
    ],
  );

  useEffect(() => {
    if (currentModule.id !== module.id) return () => {};
    if (!hasVoted) return () => {};
    if (!viewer) return () => {};

    const uid = viewer.id;
    const intervalDuration = (showInfo && showInfo.poll_interval) ? showInfo.poll_interval : 10000;
    const interval = setInterval(
      () => loadResults({
        variables: { module_id: module.id, uid },
      }), intervalDuration,
    );
    return () => {
      clearInterval(interval);
    };
  }, [currentModule, module, viewer, showInfo, hasVoted, loadResults]);

  const handleVote = useCallback(async (value) => {
    if (!viewer) return;
    const uid = viewer.id;
    const module_id = module.id;
    const res = await createVote({
      variables: {
        payload: { uid, module_id, value },
      },
    });
    const data = res.data.createMeterVote;
    setCurrentValue(data.value);
    setSavedValue(data.value);
    setHasChanged(false);
    setResult(data.result);

    showModal(
      <SuccessDialog
        image={FermiSuccessImage}
        imageOffset={-6750}
        imageWidth={125}
        imageHeight={128}
        imageSteps={54}
        color={secondaryColor}
        hasNextModule={nextModuleId !== currentModule.id}
        onClickNext={() => {
          closeModal();
          if (nextModuleId) history.replace(`/${nextModuleId}`);
        }}
        onClickResults={() => {
          setHasVoted(true);
          closeModal();
        }}
      />,
    );
  }, [
    viewer,
    createVote, showModal, closeModal,
    setHasChanged, setSavedValue, setCurrentValue, setResult, setHasVoted,
  ]);

  const handleChange = useCallback(() => {
    if (hasChanged) return;

    setHasChanged(true);
  }, [hasChanged, setHasChanged]);

  const handleVoteAgain = useCallback(() => {
    setHasVoted(false);
  }, [setHasVoted]);

  const handleShowResults = useCallback(() => {
    if (savedValue !== currentValue) return;
    setHasVoted(true);
  }, [savedValue, currentValue, setHasVoted]);

  if (loading) return null;

  return (
    <Styled.Container viewport={viewport}>
      <ModuleContainer
        title={title}
        subtitle={hasVoted ? subtitle_results || subtitle : subtitle}
        banner={banner}
        color={secondaryColor}
      >
        {!hasVoted && (
          <MoodmeterVoteScreen
            value={currentValue}
            onVote={handleVote}
            onChange={handleChange}
            onShowResults={handleShowResults}
            color={secondaryColor}
            showResultsButton={!hasChanged}
            labels={moodmeter_labels}
          />
        )}
        {hasVoted && (
          <MoodmeterResultsScreen
            value={result}
            onVoteAgain={handleVoteAgain}
            color={secondaryColor}
          />
        )}
      </ModuleContainer>
    </Styled.Container>
  );
};

export default MeterModule;
