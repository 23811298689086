import styled from 'styled-components/macro';

export const CPage = styled.div`
  padding: 1rem;
  background-color: white;
  color: var(--color-text-on-primary);
  padding-bottom: 6rem;
  height: 100%;
  &.terms {
    height: 100%;
    position: relative;
    display: block;
    font-size: 0.75rem;
    h1 {
      font-size: 1.5rem;
    }
    a {
      color: var(--color-text-on-primary);
    }
    .page-inner {
      height: 100%;
      position: relative;
      display: block;
      .page__content {
        height: 100%;
        position: relative;
      }
      // Quick fix for terms and conditions. Due to time constraints we could not check what was the
      // impact of chaning the page__content class
      .page__content__terms {
        color: rgb(0, 0, 0, 0.85);
        height: 100%;
        position: relative;
      }

      .page__content__terms a {
        color: rgb(0, 0, 0, 0.85);
      }
      // Quick fix for terms and conditions. Due to time constraints we could not check what was the
      // impact of chaning the page__content class
      .page__header__terms {
        color: rgb(0, 0, 0, 0.85);
      }

      .page__header__terms h1 {
        color: rgb(0, 0, 0, 0.85);
      }
    }
  }
  // Quick fix for about votemo page. Due to time constraints we could not check what was the
  // impact of chaning the page__content class
  &.votemo {
      .page__content__votemo {
        color: rgb(0, 0, 0, 0.85);
      }

      .page__content__votemo a {
        color: rgb(0, 0, 0, 0.85);
      }
      // Quick fix for about votemo page. Due to time constraints we could not check what was the
      // impact of chaning the page__content class
      .page__header__votemo {
        color: rgb(0, 0, 0, 0.85);
      }

      .page__header__votemo h1 {
        color: rgb(0, 0, 0, 0.85);
      }
    }
`;

export const CPageInner = styled.div`
  height: 100%;
  position: relative;
  display: block;
`;
