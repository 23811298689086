import { gql } from '@apollo/client';

const VIEWER_INFO = gql`
  query viewerInfo($domain: String!, $token: String!) {
    viewer(domain: $domain, token: $token) {
      id
      domain
      username
    }
  }
`;

export default VIEWER_INFO;
