import React, { useState, useRef, useEffect } from 'react';
import Loader from 'react-loader-spinner';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ModuleStatus } from '../../../enums/module.enums';
import {
  CProductLaunchButton,
  CProductLaunchButtonText,
  CProductLaunchModule,
  CProductLaunchVideoContainer,
  CProductLaunchVideo, CLimitReached,
} from './ProductLaunchModule.styled';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import CREATE_PRODUCT_LAUNCH_VOTE from '../../../graphql/mutations/createLaunchVote';
import { incrementVoteCount, canVote } from '../../../library/voteLimit';
import staticSource from '../../../library/staticSource';
import useShow from '../../../hooks/useShow';

const connectedPlaythroughCount = 2;

const ProductLaunchVideos = (props) => {
  const {
    setDisplayAnimation,
    setVideosLoaded,
    displayAnimation,
    module,
  } = props;
  const connectingRef = useRef(null);
  const connectedRef = useRef(null);
  const [videoSrc, setVideoSrc] = useState('connecting');
  const [connectingIterations, setConnectingIterations] = useState(0);
  const [connectingLoading, setConnectingLoading] = useState(true);
  const [connectedLoading, setConnectedLoading] = useState(true);
  useEffect(() => {
    if (connectingIterations === connectedPlaythroughCount) {
      setVideoSrc('connected');
    }
  }, [connectingIterations]);
  useEffect(() => {
    if (displayAnimation && connectingIterations < connectedPlaythroughCount + 1) {
      connectingRef.current.play();
    }
  }, [displayAnimation, connectingIterations]);
  useEffect(() => {
    if (videoSrc === 'connected' && connectedRef.current) {
      connectedRef.current.play();
    }
  }, [videoSrc, connectedRef]);
  useEffect(() => {
    if (!connectingLoading && !connectedLoading) {
      setVideosLoaded(true);
    }
  }, [connectingLoading, connectedLoading, setVideosLoaded]);
  return (
    <CProductLaunchVideoContainer
      style={{ opacity: (connectingLoading || connectedLoading || !displayAnimation) ? '0' : '1' }}
    >
      <CProductLaunchVideo
        ref={connectingRef}
        playsInline
        muted
        src={`${process.env.PUBLIC_URL}/videos/connecting2.mp4`}
        controls={false}
        preload="auto"
        style={{ visibility: videoSrc === 'connecting' ? 'visible' : 'hidden' }}
        onLoadedMetadata={() => {
          setConnectingLoading(false);
        }}
        onEnded={() => {
          setConnectingIterations(connectingIterations + 1);
          if (connectingRef.current) {
            connectingRef.current.play();
          }
        }}
      />
      <CProductLaunchVideo
        ref={connectedRef}
        playsInline
        muted
        src={`${process.env.PUBLIC_URL}/videos/connected2.mp4`}
        style={{ visibility: videoSrc === 'connecting' ? 'hidden' : 'visible' }}
        controls={false}
        preload="auto"
        onPlay={() => {
          connectingRef.current.pause();
          connectingRef.current.currentTime = 0;
          incrementVoteCount(module);
        }}
        onLoadedMetadata={() => {
          setConnectedLoading(false);
        }}
        onEnded={() => {
          setTimeout(() => {
            setVideoSrc('connecting');
            setDisplayAnimation(false);
            if (connectedRef.current) {
              connectedRef.current.currentTime = 0;
            }
            setConnectingIterations(0);
          }, 1000);
        }}
      />
    </CProductLaunchVideoContainer>
  );
};

const BannerImage = ({ source }) => (
  <img
    src={source}
    alt="module-ended"
    style={{
      position: 'absolute', top: '50%', transform: 'translateY(-50%)', width: '100%', height: 'auto',
    }}
  />
);

const ProductLaunchModule = ({ module }) => {
  const { cta_text } = module;
  const { trackingId } = useShow();
  const { t } = useTranslation();
  const [buttonContainerClassNames, setButtonContainerClassNames] = useState(['animated', 'long', ', fadeIn']);
  const [videosLoaded, setVideosLoaded] = useState(false);
  const [displayAnimation, setDisplayAnimation] = useState(false);
  const [buttonWidth, setButtonWidth] = useState(null);
  const [buttonBorderWidth, setButtonBorderWidth] = useState(null);
  const [buttonTextSize, setButtonTextSize] = useState(null);
  const { height, width } = useWindowDimensions();
  const [createProductLaunchVote] = useMutation(CREATE_PRODUCT_LAUNCH_VOTE);
  useEffect(() => {
    setButtonWidth((width / 100) * 30);
    setButtonBorderWidth(width / 50);
  }, [width]);

  useEffect(() => {
    if (buttonWidth) {
      setButtonTextSize(buttonWidth / 12);
    }
  }, [buttonWidth]);

  return (
    <>
      <CProductLaunchModule height={height}>
        {(module.status === ModuleStatus.Live || module.status === ModuleStatus.NotStarted) && (
          <>
            {((!videosLoaded && canVote(module)) && module.status === ModuleStatus.Live) && (
              <Loader
                type="Grid"
                color="#fff"
                height={100}
                width={100}
                style={{
                  margin: 'auto auto',
                  alignSelf: 'center',
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              />
            )}
            {!canVote(module) ? (
              <CLimitReached>{t('modules.product_launch.vote_limit')}</CLimitReached>
            ) : (
              <>
                <ProductLaunchVideos
                  setDisplayAnimation={setDisplayAnimation}
                  setVideosLoaded={setVideosLoaded}
                  displayAnimation={displayAnimation}
                  module={module}
                />
                {module.status === ModuleStatus.Live ? (
                  <>
                    {!displayAnimation && (
                      <div className={buttonContainerClassNames.join(' ')}>
                        {videosLoaded && module.status === ModuleStatus.Live && (
                          <CProductLaunchButton
                            onClick={() => {
                              createProductLaunchVote({
                                variables: {
                                  module_id: module.id,
                                  pl_type: 'LIVE',
                                  tracking_id: trackingId,
                                },
                              });
                              setButtonContainerClassNames(['animated', 'long', 'fadeOut']);
                              setTimeout(() => {
                                setDisplayAnimation(true);
                                setButtonContainerClassNames(['animated', 'long', 'fadeIn']);
                              }, 1100);
                            }}
                            buttonWidth={buttonWidth}
                            buttonBorderWidth={buttonBorderWidth}
                          >
                            <CProductLaunchButtonText buttonFontSize={buttonTextSize}>
                              {cta_text}
                            </CProductLaunchButtonText>
                          </CProductLaunchButton>
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  <CLimitReached>{t('modules.product_launch.not_started')}</CLimitReached>
                )}
              </>
            )}
          </>
        )}
        {module.status === 'ENDED' && module.banner_image !== '' && (
          <>
            {module.header_text && module.header_text !== '' && module.header_text !== ' ' ? (
              <a
                href={module.header_text}
                target="_blank"
                aria-label="header-text"
                rel="noopener noreferrer"
                onClick={() => createProductLaunchVote({
                  variables: {
                    module_id: module.id,
                    pl_type: 'ENDED',
                    tracking_id: trackingId,
                  },
                })}
              >
                <BannerImage source={staticSource(module.banner_image)} />
              </a>
            ) : (
              <BannerImage source={staticSource(module.banner_image)} />
            )}

          </>
        )}
      </CProductLaunchModule>
    </>
  );
};

export default ProductLaunchModule;
