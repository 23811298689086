import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { sizes, colors } from '../../library/styleAbstracts/variables';
import useShow from '../../hooks/useShow';
import staticSource from '../../library/staticSource';

import Logo from '../../assets/img/logo_votemo.png';
import Menu from './Menu';

// @todo: Some styled-components here should be with more complex logic
const CHeader = styled.header`
  height: ${sizes.headerHeight};
  transition: all 0.25s ease-in-out;
  opacity: 1;
`;

const CHeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  padding: .5rem 1rem;
  background-color: ${colors.headerBG};
  overflow: hidden;
  height: ${sizes.headerHeight};
`;

const CLogo = styled.div`
  display: flex;
  align-items: center;
  a {
    display: inline-block;
  }
  img {
    max-height: 32px;
    width: auto;
  }
`;

const CMenuButtonContainer = styled.div`
  width: 2rem;
  height: 2rem;
  align-self: center;
  button {
    width: 100%;
    height: 100%;
    padding: 0;
    border: 0;
    background-color: transparent;
    user-select: none;
    &:focus {
      outline: 0;
    }
  }
  .menu-line {
    width: 100%;
    display: block;
    height: 3px;
    background-color: ${colors.menuToggleColor};
    margin-bottom: 0.4rem;
    border-radius: 1px;
    transition: all 0.25s ease-in;
    &:last-child {
      margin-bottom: 0;
      width: 70%;
      position: relative;
      right: 0;
      margin-left: auto;
    }
  }
`;

const CMenuIcon = styled.div`
  cursor: pointer;
  &.open {
    .menu-line {
      transform: translateY(0) rotate(135deg);
      &:first-child {
        transform: translateY(0.6rem) rotate(-135deg);
      }
      &:last-child {
        opacity: 0;
        right: -100%;
      }
    }
  }
`;

const Header = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const { showTheme, showMenu } = useShow();
  let logo;
  if (showTheme.image) {
    logo = staticSource(showTheme.image);
  } else {
    logo = Logo;
  }
  const { modules } = useShow();
  if (!modules) {
    return <div>Loading...</div>;
  }
  let firstModuleID = '';
  if (modules.length > 0) {
    firstModuleID = modules[0].id;
  }

  const menuIconClassList = [];
  if (isMenuVisible) {
    menuIconClassList.push('open');
  }
  return (
    <CHeader>
      <CHeaderInner>
        <CLogo>
          <NavLink to={`/${firstModuleID}`} onClick={() => isMenuVisible && setIsMenuVisible(!isMenuVisible)}>
            <img src={logo} alt="logo" />
          </NavLink>
        </CLogo>
        {showMenu && (
        <CMenuButtonContainer>
          <button type="button" aria-label="menu-button" onClick={() => setIsMenuVisible(!isMenuVisible)}>
            <CMenuIcon className={menuIconClassList.join(' ')}>
              <span className="menu-line" />
              <span className="menu-line" />
              <span className="menu-line" />
            </CMenuIcon>
          </button>
        </CMenuButtonContainer>
        )}
      </CHeaderInner>
      <Menu isMenuVisible={isMenuVisible} setIsMenuVisible={setIsMenuVisible} />
    </CHeader>
  );
};

export default Header;
