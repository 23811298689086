import { gql } from '@apollo/client';

const WORD_SUBMIT = gql`
  mutation ($payload: WordCloudCreateInput!) {
    submitWord(payload: $payload) {
      text
      count
    }
  }
`;

export default WORD_SUBMIT;
