import { gql } from '@apollo/client';

// TODO: Refactor 'Show' -> 'Event'
const LIST_EVENTS = gql`
    query ($domain_name: String!) {
        showByDomain(domain_name: $domain_name) {
            name
            display_info
            display_header
            display_menu
            description
            primary_color
            secondary_color
            language
            image
            updated_at
            poll_interval
            whitelabel
            company_name
            episode {
                id
                name
                display_info
                description
                image
                show_terms
                terms_location
                modules {
                    id
                    image
                    title
                    subtitle
                    subtitle_ended
                    subtitle_not_started
                    subtitle_results
                    type
                    vote_limit
                    subtype
                    is_paid
                    price
                    megavote_amount
                    megavote_price
                    show_results
                    show_total_count
                    show_all_comments
                    show_new_comments
                    show_own_comments
                    collect_contact_details
                    contact_cta_text
                    status
                    cta_text
                    moodmeter_labels
                    banner_image
                    header_text
                    options {
                        id
                        title
                        subtitle
                        image
                    }
                }
            }
        }
    }
    
`;

export default LIST_EVENTS;
