import { noop } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

const useDoublePress = (handler, time = 500) => {
  const [pressed, setPressed] = useState(null);

  useEffect(() => {
    setPressed(null);
  }, [handler, time]);

  useEffect(() => {
    if (pressed === null) return noop;

    // Reset after time
    const timeout = setTimeout(
      () => setPressed(null),
      time,
    );
    return () => {
      clearTimeout(timeout);
    };
  }, [handler, time, pressed]);

  return useCallback(() => {
    const now = Date.now();
    if (pressed && (now - pressed) <= time) {
      setPressed(null);
      handler();
      return;
    }

    setPressed(Date.now());
  }, [pressed, setPressed]);
};

export default useDoublePress;
