import moment from 'moment';
import { useEffect, useState } from 'react';

export default {};
export const useTimeSince = (time, interval = undefined) => {
  const [timeSince, setTimeSince] = useState(
    moment(time).fromNow(),
  );

  useEffect(() => {
    if (!interval || interval < 0) {
      return () => {};
    }

    const int = setInterval(() => {
      setTimeSince(moment(time).fromNow());
    }, interval);

    return () => {
      clearInterval(int);
    };
  }, [time, interval]);

  return timeSince;
};
