import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import WordCloudResult from './WordCloudResult';

const WordCloudResultsContainer = styled.div`
  margin: 0;
  flex-grow: 1;
  overflow-y: auto;
  padding: 0 16px 100px;

  display: flex;
  flex-direction: column;

  h2 {
    text-align: center;
  }
`;

const WordCloudResults = ({ data }) => {
  const orderedData = useMemo(() => {
    if (!data) return [];
    return data.sort((a, b) => b.value - a.value);
  }, [data]);

  if (!orderedData.length) {
    return (
      <WordCloudResultsContainer>
        <h2>Top words</h2>
        <span style={{ textAlign: 'center', opacity: 0.5, margin: 'auto 0' }}>No words submitted yet</span>
      </WordCloudResultsContainer>
    );
  }

  return (
    <WordCloudResultsContainer>
      <h2>Top words</h2>
      {orderedData.map((item, index) => (
        <WordCloudResult key={item.text} index={index} count={item.value}>
          {item.text}
        </WordCloudResult>
      ))}
    </WordCloudResultsContainer>
  );
};

export default WordCloudResults;
