import React from 'react';
import { useTranslation } from 'react-i18next';
import SuccessImage from '../../../../assets/img/celebrate_2.png';
import Sprite from '../../../Sprite/Sprite';

const CommentSuccessModal = ({ setShowModal, onNextModule }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="modal__header">
        <div className="modal__title">
          <div className="modal__icon animated bounceIn">
            <Sprite
              imageUrl={SuccessImage}
              height={80}
              width={80}
              offset={-4000}
              steps={50}
            />
          </div>
        </div>
      </div>
      <div className="modal__content" style={{ textAlign: 'center' }}>
        <h2>{t('modules.comment.form.success_title')}</h2>
        <p>{t('modules.comment.form.success_body')}</p>
      </div>
      <div className="modal__actions">
        <button
          type="button"
          className={onNextModule ? 'actions__secondary comment' : 'actions__confirm comment'}
          onClick={() => setShowModal(false)}
        >
          {t('modules.comment.form.ok')}
        </button>
        {onNextModule && (
          <button
            type="button"
            className="actions__confirm comment"
            onClick={() => {
              setShowModal(false);
              onNextModule();
            }}
          >
            {t('modules.comment.form.next')}
          </button>
        )}
      </div>
    </>
  );
};

export default CommentSuccessModal;
