import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

const CNavButton = styled(NavLink)`
  padding: 0.75rem 1rem;
  color: var(--color-text-on-primary);
  font-weight: normal;
  border: 1px solid var(--color-primary);
  background-color: rgba(var(--color-primary-rgb-string), 0.75);
  border-radius: 4px;
  &[disabled] {
    background-color: rgba(var(--color-primary-rgb-string), 0.25);
    border: 1px solid rgba(var(--color-primary-rgb-string), 0.25);
    opacity: 0;
  }
`;

const NavButton = ({ next, to, disabled }) => {
  const [iconClassNames, setIconClassNames] = useState(['fa']);

  useEffect(() => {
    const arrowType = next === true ? 'fa-arrow-right' : 'fa-arrow-left';
    // eslint-disable-next-line no-shadow
    setIconClassNames((iconClassNames) => [...iconClassNames, arrowType]);
  }, [next]);

  return (
    <CNavButton
      to={`/${to}`}
      disabled={disabled}
      onClick={(event) => (disabled && event.preventDefault())}
    >
      <i className={iconClassNames.join(' ')} />
    </CNavButton>
  );
};

export default NavButton;
