import React from 'react';
import { useTranslation } from 'react-i18next';
import useShow from '../../hooks/useShow';
import staticSource from '../../library/staticSource';

const WaitingPage = () => {
  const { t } = useTranslation();
  const { episodeInfo } = useShow();

  return (
    <div className="page welcome">
      {episodeInfo && episodeInfo.image && (
        <div
          className="welcome-background"
          style={{
            backgroundImage: `url(${staticSource(episodeInfo.image)})`,
          }}
        />
      )}
      <div className="page-inner">
        <div className="page__title">
          <h1 style={{ textTransform: 'none' }}>{t('pages.waiting.come_back')}</h1>
        </div>
      </div>
    </div>
  );
};

export default WaitingPage;
