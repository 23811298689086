import React from 'react';
import styled, { css } from 'styled-components/macro';

const containerHeight = ({ viewport }) => (
  viewport.height - (viewport.top + viewport.bottom)
);
export const Container = styled.div`
  height: ${containerHeight}px;
  display: flex;
  flex-direction: column;
  background-color: #2303BE;
`;

export const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const Button = styled.button`
  display: inline-flex;
  align-items: center;
  color: white;
  text-align: left;
  min-width: 100px;
  margin: 0.5rem 0 0 auto;
  cursor: pointer;
  font-weight: 700;
  padding: 0 1em;
  
  transition: all 300ms ease-in-out;

  span {
    border-bottom: 1px solid white;
    padding: .5em;
    margin-right: .5em;
  }

  ${({ inverted, color }) => inverted && css`
    color: ${color};
    background-color: white;
  `}
`;

export const ArrowButton = ({ children, ...props }) => (
  <Button {...props}>
    <span>{children}</span>
    <i className="fa fa-chevron-right" />
  </Button>
);
