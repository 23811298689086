import { gql } from '@apollo/client';

const CREATE_METER_VOTE = gql`
  mutation createMeterVote($payload: MeterVoteInput!) {
    createMeterVote(payload: $payload) {
      success,
      result,
      value,
    }
  }
`;

export default CREATE_METER_VOTE;
