import styled from 'styled-components/macro';

export const Container = styled.div`
  flex-grow: 1;
  background-color: white;
  border-radius: 0 0.5rem 0.5rem 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
`;
export const Header = styled.header`
  display: flex;
  align-items: center;
  height: 1rem;
  width: 100%;
  padding-right: 32px;
`;
export const HeaderTimestamp = styled.time`
  color: #bababb;
  margin-left: 0.5rem;
  flex-shrink: 0;

  &:before {
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    font-size: 5px;
    content: "\f111";
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5rem;
  }
`;
const authorColor = ({ color, moderatedColor, isModerated }) => {
  if (isModerated) {
    return moderatedColor;
  }

  return color;
};
export const HeaderAuthor = styled.div`
  font-weight: bold;
  color: ${authorColor};
  flex-shrink: 1;
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const ModeratedIcon = styled.div`
  margin-left: 0.2rem;
  width: 1rem;
  height: 1rem;

  img {
    width: 100%;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
`;
export const Content = styled.p`
  margin: 0;
  display: flex;
  flex-grow: 1;
  overflow-wrap: anywhere;
`;

export const ControlsWrapper = styled.div`
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
  align-items: center;
  min-width: 40px;
`;
export const ReplyContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 0 8px;
  
  & > :not(:first-child) {
    margin-left: 8px;
  }
`;
export const RepliesTotal = styled.span`
  font-size: 14px;
`;
export const ReplyButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-color: transparent;
  border: 0;
  color: inherit;
  height: 12px;
  cursor: pointer;
`;
const upvoteButtonColor = ({ hasUpvoted, color = 'black', upvoteColor = 'blue' }) => (
  hasUpvoted ? upvoteColor : color
);
export const UpvoteContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${upvoteButtonColor};
  margin: 0 0 0 8px;
`;
export const UpvoteTotal = styled.div`
  font-size: 14px;
`;
export const UpvoteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: 0;
  color: inherit;
  height: 12px;
  cursor: pointer;
`;
