import styled from 'styled-components/macro';

export const BottomInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #ebebeb;
  bottom: 6px;
  left: ${({ viewport }) => viewport.left}px;
  right: ${({ viewport }) => viewport.right}px;
  box-sizing: border-box;
`;

export const BottomInputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const BottomInputTextareaWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  margin: 8px 0;

  textarea {
    width: 100%;
    padding: 12px 72px 12px 16px;
    border: none;
    font-size: 12px;
    outline: none;
  }
`;

export const BottomInputButtonWrapper = styled.div`
  position: absolute;
  right: .425rem;
  top: 50%;
  transform: translateY(-50%);
`;

export const BottomInputButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4px;
  font-size: .75rem;
  padding: .35rem;
  border-radius: .2rem;
  color: #ffffff;
  background-color: #111cf0;

  &:disabled, &[disabled] {
    color: #505151;
    background-color: lightgrey;
  }
`;

export const BottomInputLengthContainer = styled.div`
  display: flex;
  font-size: .6rem;
`;
