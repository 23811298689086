import styled from 'styled-components/macro';
import * as StyledThread from '../Thread/Thread.styled';

export const ReplyWrapper = styled.div`
  padding-left: 32px;
`;
export const Container = styled(StyledThread.Container)`
  border-radius: 0.5rem 0 0.5rem 0.5rem;
`;
export const Header = styled(StyledThread.Header)``;
export const HeaderTimestamp = styled(StyledThread.HeaderTimestamp)``;
export const HeaderAuthor = styled(StyledThread.HeaderAuthor)``;
export const ModeratedIcon = styled(StyledThread.ModeratedIcon)``;
export const ContentWrapper = styled(StyledThread.ContentWrapper)``;
export const Content = styled(StyledThread.Content)``;

export const ControlsWrapper = styled(StyledThread.ControlsWrapper)``;
export const UpvoteContainer = styled(StyledThread.UpvoteContainer)``;
export const UpvoteTotal = styled(StyledThread.UpvoteTotal)``;
export const UpvoteButton = styled(StyledThread.UpvoteButton)``;
