import React from 'react';

import staticSource from '../../../../library/staticSource';

const Option = ({ isPhoto, optionData }) => {
  const {
    title, subtitle,
  } = optionData;
  const imageSrc = isPhoto && staticSource(optionData.image);

  return (
    <>
      {isPhoto && (
        <div className="option__image">
          <img src={imageSrc} alt="" style={{ height: '60px', width: '60px' }} />
        </div>
      )}
      <div className="option__data">
        <div className="option__title">
          <h4 className="title">{title}</h4>
        </div>
        <div className="option__subtitle">
          <p className="subtitle">{subtitle}</p>
        </div>
      </div>
    </>
  );
};

export default Option;
