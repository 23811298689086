import React from 'react';
import * as Styled from './InputField.styled';

const InputFieldLimit = ({ length, maxLength }) => {
  if (!maxLength) {
    return (
      <Styled.LengthWrapper>
        <span>{length}</span>
      </Styled.LengthWrapper>
    );
  }

  return (
    <Styled.LengthWrapper>
      <span>{length}</span>&nbsp;/&nbsp;<span>{maxLength}</span>
    </Styled.LengthWrapper>
  );
};
export default InputFieldLimit;
