export const sizes = {
  defaultLineHeight: 1.15,
  headerHeight: '3.5rem',
  footerHeight: '1.3rem',
  navButtonMargins: '0.5rem',
  navButtonHeight: '2.75rem',
};

export const breakPoints = {
  xs: '0',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
};

export const fontStackText = "'Montserrat', sans-serif";

export const zIndex = {
  navButtons: 5,
  menu: 10,
  scrollbar: 15,
  modal: 20,
};

const initialColors = {
  lightGray2: '#ebebeb',
  red: '#cd0000',
  lightGray: '#f8f8f8',
  blue: '#111cf0',
  almostWhite: '#fefeff',
  white: '#fff',
  yellow: '#ffc540',
  gray: '#acacac',
  gray2: '#bababb',
  gray3: '#f7f7f7',
  darkGray: '#505151',
  blueGray: '#313A50',
};

export const colors = {
  indicatorInactive: initialColors.gray,
  headerBG: initialColors.lightGray,
  menuToggleColor: initialColors.darkGray,
  menuBorderColor: initialColors.gray2,
  moduleMenuItemColor: initialColors.blueGray,
  staticMenuItemColor: initialColors.gray2,
  defaultModuleBackground: initialColors.white,
  darkModuleBackground: initialColors.lightGray2,
  reactionBorderColor: initialColors.gray3,
};
