import React, { useState } from 'react';
import ReactModal from 'react-modal';

export const ModalContext = React.createContext({
  modalRef: null,
  showModal: () => {},
  closeModal: () => {},
});

export default ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState(null);

  const showModal = (modalContent) => {
    setContent(modalContent);
    setIsOpen(true);
  };
  const closeModal = () => {
    setContent(null);
    setIsOpen(false);
  };

  return (
    <ModalContext.Provider value={{ showModal, closeModal }}>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className="vote-modal animated zoomInUp"
      >
        {content}
      </ReactModal>
      {children}
    </ModalContext.Provider>
  );
};
