import React from 'react';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CPage } from './styled/StyledComponentsPages';
import useShow from '../../hooks/useShow';
import useWelcome from '../../hooks/useWelcome';

// TODO: this is temporary and should be removed after the event. Just for one client.
// Customizations flags should come from the backend.
// https://vntrs-company.monday.com/boards/3206830247/pulses/4587285115
function IsCustomClient(showInfo) {
  return ['Montclair Film Festival'].includes(
    showInfo.company_name,
  );
}

const TermsPage = (props) => {
  const { isModal, setShowTerms, allowBack } = props;
  const pageClassList = ['terms'];
  const { language, showInfo } = useShow();
  const { t } = useTranslation();
  const { isTermsAccepted } = useWelcome();
  if (language === 'lv') {
    return (
      <CPage className={pageClassList.join(' ')}>
        <div className="page-inner">
          { (isTermsAccepted() || allowBack) && (
            <div className="back" style={{ paddingBottom: '0.5rem' }}>
              {isModal ? (
                <button className="btn-link" type="button" onClick={() => setShowTerms(false)}>
                  {t('pages.terms.go_back')}
                </button>
              ) : (
                <Link to="/">{t('pages.terms.go_back')}</Link>
              )}
            </div>
          )}
          <PerfectScrollBar
            options={{
              suppressScrollX: true,
            }}
          >
            <div className="page__title">
              <h1>Votemo lietošanas noteikumi</h1>
              <p>* Noteikumi ir spēkā no 01.09.2019.</p>
            </div>

            <div className="page__content">
              <p>
                Votemo atbalsta viedokli, ka komentēšanas un balsošanas iespēja interneta vidē ir demokrātijas izpausme,
                kas nodrošina sabiedrības iespēju brīvi diskutēt par dažādām tēmām, paužot savus uzskatus un viedokli.
              </p>
              <h2>
                1. BALSOŠANA
              </h2>
              <p>
                Piekrītot lietošanas noteikumiem vai balsojot, izmantojot Votemo maksas balsošanas lietotni,
                {/* eslint-disable-next-line max-len */}
                piešķirat Votemo OÜ tiesības nodot tādus Jūsu personas datus kā tālruņa numuru un mobilo sakaru operatoru
                mūsu sadarbības partneriem (mikromaksājumu starpnieks, mobilo sakaru operators, televīzijas kanāls)
                un tiesības apstrādāt Jūsu personas datus balsošanas rezultātu noskaidrošanai.
              </p>
              <p>
                Izmantojam Jūsu personas datus, lai izpildītu ar Jums noslēgto līgumu vai procedūras pirms līguma
                noslēgšanas; juridisko saistību izpildei; pakalpojumu piedāvāšanai vai citam klientu
                apkalpošanas mērķim šādi:
              </p>
              <ol>
                <li>Jūsu vai Jūsu pārstāvja identificēšanai, piemēram, pildot juridisku saistību;</li>
                <li>interneta vietnes satura parādīšanai Jums un Jūsu iekārtā visefektīvākajā veidā;</li>
                <li>pētījumu veikšanai, lai sniegtu Jums vislabāko lietotāja pieredzi;</li>
                <li>
                  dalības, reģistrēšanās, balsošanas un/vai uzvarētāju noskaidrošanas konkursos
                  un/vai spēlēs ar balvām nodrošināšanai;
                </li>
                <li>lai reģistrētos raidījumos un/vai projektos, piedalītos tajos;</li>
                <li>
                  personas datu nosūtīšanai raidījumu ražotājfirmām,
                  ar mērķi reģistrēties un piedalīties raidījumos;
                </li>
                <li>reklāmas piedāvājumu nosūtīšanai;</li>
                <li>komentāru publicēšanai;</li>
                <li>godalgu un/vai produktu piegādāšanai;</li>
                <li>
                  maksas balsojumu gadījumā maksājumu veikšanai sadarbībā ar mikromaksājumu pakalpojuma piedāvātājiem
                  MobiGW OÜ un Zlick Ltd, kā arī Jūsu mobilo sakaru operatoru.
                </li>
              </ol>
              <p>
                Votemo OÜ datu aizsardzības noteikumi un sīkdatņu izmantošanas
                principi atrodas <a rel="noopener noreferrer" target="_blank" href="https://www.votemo.eu/privacy-policy">šeit.</a>
              </p>
              <h2>
                2. KOMENTĒŠANA
              </h2>
              <p>
                Rakstot komentārus Votemo interneta lietotnē, komentētājs piekrīt tālāk minētajam:
              </p>
              <ol>
                <li>
                  Komentāru rakstīšana nav žurnālistiska darbība. Komentāra rakstītājs komentārā pauž savu viedokli,
                  par kuru Votemo vai Votemo sadarbības partneris (televīzijas kanāls vai producents)
                  nevar atbildēt un tam nav par to jāatbild;
                </li>
                <li>Komentētājs atbild par saviem vārdiem un saprot, ka, arī nepublicējot savu vārdu,
                  neviens uzrakstītais komentārs nav anonīms apjomā, kas neļautu identificēt komentāra
                  rakstītāja personu;
                </li>
                <li>
                  Ja komentārs pārkāpj trešās personas tiesības, Votemo ir tiesības publiskot komentētāja datus
                  tiesībsargājošajām iestādēm un/vai tiesai;
                </li>
                <li>
                  Komentārs nedrīkst saturēt rupjības, mudināt uz nacionālo un rasu naidu, karu,
                  aicināt uz narkotiku un ieroču lietošanu, valsts apvērsumu vai nodevību, vai fizisku vardarbību pret
                  konkrētām personām, izplatīt melus vai nepamatoti nosodīt citas personas;
                </li>
                <li>
                  Publicējot komentāru, komentētājs nodod Votemo tiesības reproducēt komentāru pēc saviem ieskatiem,
                  kā arī atsakās no autora mantiskajām tiesībām attiecībā uz komentāra saturu;
                </li>
                <li>
                  Konstatējot nepiemērotu komentāru, lūdzam par to nekavējoties paziņot, rakstot uz adresi
                  support@votemo.eu. Izsekojot komentārus, Votemo vadās no
                  principa &quot;informēšana un izņemšana&quot;
                  (notice-and-take-down). Lasītājs informē par sliktu komentāru un Votemo šādu komentāru izņem.
                </li>
                <p>Votemo ir tiesības:</p>
                <li>
                  dzēst komentārus, kas neatbilst labām paražām un/vai Votemo komentēšanas noteikumiem;
                </li>
                <li>
                  saglabāt un apstrādāt komentētāja IP adresi, kā arī nepieciešamības gadījumā
                  nosūtīt to tiesībsargājošajām iestādēm un/vai tiesai;
                </li>
                <li>
                  piemērot pasākumus attiecībā uz personām, kas publicē nepiemērotus komentārus
                  (ja nepieciešams, bloķēt IP adresi, informēt tiesībsargājošās iestādes).
                </li>
              </ol>
              <h2>3. SŪDZĪBU IESNIEGŠANA</h2>
              <ol>
                <li>
                  Ja Jums šķiet, ka Votemo Jūsu datus apstrādā nelikumīgi, Jums ir tiesības vērsties ar sūdzību
                  Igaunijas Datu aizsardzības Inspekcijā (Estonian Data Protection Inspectorate), atbilstošā datu
                  aizsardzības organizācijā Jūsu atrašanās valstī vai tiesā.
                </li>
                <li>
                  Ja vēlaties vairāk informācijas par Votemo Lietošanas noteikumiem,
                  Privātuma noteikumiem v.c. noteikumiem, vērsieties pie Votemo datu aizsardzības
                  speciālista, rakstot uz adresi support@votemo.eu vai Tehnika 25-11, Tallina, 10149, Estonia.
                </li>
              </ol>
              <h2>4. DATU AIZSARDZĪBAS NOTEIKUMI UN SĪKDATŅU IZMANTOŠANAS PRINCIPI</h2>
              <p>
                Votemo OÜ datu aizsardzības noteikumi un sīkdatņu izmantošanas principi
                atrodas <a rel="noopener noreferrer" target="_blank" href="https://www.votemo.eu/privacy-policy">šeit</a>.
              </p>
            </div>
          </PerfectScrollBar>
          { (isTermsAccepted() || allowBack) && isModal && (
            <div className="back" style={{ paddingBottom: '0.5rem', paddingTop: '1rem' }}>
              {isModal ? (
                <button className="btn-link" type="button" onClick={() => setShowTerms(false)}>
                  {t('pages.terms.go_back')}
                </button>
              ) : (
                <Link to="/">{t('pages.terms.go_back')}</Link>
              )}
            </div>
          )}
        </div>
      </CPage>
    );
  } if (language === 'et') {
    return (
      <CPage className={pageClassList.join(' ')}>
        <div className="page-inner">
          { (isTermsAccepted() || allowBack) && (
            <div className="back" style={{ paddingBottom: '0.5rem' }}>
              {isModal ? (
                <button className="btn-link" type="button" onClick={() => setShowTerms(false)}>
                  {t('pages.terms.go_back')}
                </button>
              ) : (
                <Link to="/">{t('pages.terms.go_back')}</Link>
              )}
            </div>
          )}
          <PerfectScrollBar
            options={{
              suppressScrollX: true,
            }}
          >
            <div className="page__header__terms">
              <h1>Votemo kasutustingimused</h1>
              <p>* Tingimused kehtivad alates 01.09.2019.</p>
            </div>

            <div className="page__content__terms">
              <p>
                Votemo toetab seisukohta, et kommenteerimise ja hääletamise võimalus veebikeskkonnas on demokraatia
                ilming, mis võimaldab ühiskonnas vabalt diskuteerida erinevatel teemadel oma arvamust
                ja seisukohti väljendades.
              </p>
              <h2>
                1. HÄÄLETAMINE
              </h2>
              <p>
                Nõustudes kasutustingimustega või hääletades läbi Votemo tasulise hääletuse rakenduse annate
                Votemo OÜ-le õiguse jagada Teie isikuandmeid nagu telefoninumber ja mobiilioperaator, meie
                koostööpartneritega (mikromaksevahendaja, mobiilioperaator, telekanal) ja õiguse töödelda Teie
                isikuandmeid hääletustulemuste väljaselgitamiseks.
              </p>
              <p>
                Kasutame Teie isikuandmeid Teiega sõlmitud lepingu täitmiseks või lepingu sõlmimisele eelnevate
                protseduuride läbiviimiseks; juriidilise kohustuse täitmiseks; teenuste pakkumiseks või muul
                klienditeeninduslikul eesmärgil järgmiselt:
              </p>
              <ol>
                <li>Teie või Teie esindaja tuvastamiseks näiteks juriidilise kohustuse täitmisel;</li>
                <li>veebilehe sisu esitamiseks Teile ja Teie seadmesse kõige tõhusamal moel;</li>
                <li>uuringute läbiviimiseks, et pakkuda Teile paremat kasutajakogemust;</li>
                <li>
                  konkurssidel ja/või auhinnamängudel osalemiseks, registreerimiseks, hääletamiseks ja/või võitjate
                  väljaselgitamiseks;
                </li>
                <li>saadetesse ja/või projektidesse registreerumiseks, nendes osalemiseks;</li>
                <li>
                  saadete tootjafirmadele isikuandmete edastamiseks, saadetesse registreerumise ja saadetes
                  osalemise eesmärgil;
                </li>
                <li>reklaamipakkumiste edastamiseks;</li>
                <li>kommentaaride avaldamiseks;</li>
                <li>auhindade ja/või toodete kättetoimetamiseks;</li>
                <li>
                  tasulise hääletuse puhul maksete läbiviimiseks koostöös mikromaksete teenusepakkujate MobiGW OÜ
                  ja ​​Zlick Ltd ning Teie mobiilioperaatoriga.
                </li>
              </ol>
              <p>
                Votemo OÜ andmekaitsetingimused ja küpsiste kasutamise põhimõtted asuvad&nbsp;
                <a rel="noopener noreferrer" target="_blank" href="https://www.votemo.eu/privacy-policy">siin.</a>
              </p>
              <h2>
                2. KOMMENTEERIMINE
              </h2>
              <p>
                Votemo veebirakenduses kommentaare kirjutades nõustub kommenteerija alljärgnevaga:
              </p>
              <ol>
                <li>
                  Kommentaaride kirjutamine ei ole ajakirjanduslik tegevus. Kommentaari kirjutaja avaldab kommentaaris
                  oma seisukohta, mille eest Votemo või Votemo koostööpartner (telekanal või produtsent) ei saa ega pea
                  vastutama;
                </li>
                <li>Kommenteerija vastutab oma sõnade eest ning mõistab, et ka oma nime avaldamata ei ole
                  ükski kirjutatud kommentaar anonüümne määral, mis ei võimaldaks kommentaari kirjutaja
                  isikut tuvastada;
                </li>
                <li>
                  Kui kommentaar rikub kolmanda isiku õigusi, on Votemol õigus avaldada kommenteerija andmed
                  õiguskaitseorganitele ja/või kohtule;
                </li>
                <li>
                  Kommentaar ei tohi sisaldada roppusi, õhutada rahvaste ja rasside vahelist vaenu, sõda,
                  kutsuda üles narkootikumide ja relvade kasutamisele, riigi kukutamisele või reetmisele
                  või konkreetsete isikute suhtes füüsilisele vägivallale, levitada valet või halvustada
                  põhjendamatult teisi isikuid;
                </li>
                <li>
                  Kommentaari avaldamisega annab kommenteerija Votemole õiguse kommentaari omal äranägemisel
                  reprodutseerida ning loobub autori varalistest õigustest kommentaari sisu osas;
                </li>
                <li>
                  Sobimatu kommentaari avastamisel palume sellest viivitamata teavitada aadressil
                  support@votemo.eu. Votemo lähtub kommentaaride jälgimisel &quot;märka ja võta&quot; maha
                  (notice-and-take-down) põhimõttest. Lugeja teavitab halvast kommentaarist ja Votemo
                  võtab sellise kommentaari maha.
                </li>
                <p>Votemol on õigus:</p>
                <li>
                  kustutada kommentaarid, mis ei vasta heale tavale ja/või Votemo kommenteerimistingimustele;
                </li>
                <li>
                  säilitada ja töödelda kommenteerija IP-aadressi ning edastada see vajadusel
                  õiguskaitseorganitele ja/või kohtule;
                </li>
                <li>
                  rakendada meetmeid isikute suhtes, kes avaldavad ebasobivaid kommentaare (vajadusel blokeerida
                  IP-aadress, teavitada õiguskaitseorganeid).
                </li>
              </ol>
              <h2>3. KAEBUSTE ESITAMINE</h2>
              <ol>
                <li>
                  Kui Teile tundub, et Votemo töötleb Teie andmeid ebaseaduslikult, on Teil õigus pöörduda kaebusega
                  Eesti Andmekaitse Inspektsiooni poole (Estonian Data Protection Inspectorate), vastava
                  andmekaitseorganisatsiooni poole Teie asukohariigis või kohtu poole.
                </li>
                <li>
                  Kui soovite rohkem infot Votemo Kasutustingimuste, Privaatsustingimuste vm reeglite kohta,
                  pöörduge Votemo andmekaitsespetsialisti poole aadressil support@votemo.eu või Tehnika 25-11,
                  Tallinn 10149 Estonia.
                </li>
              </ol>
              <h2>4. ANDMEKAITSETINGIMSUED JA KÜPSISTE KASUTAMISE PÕHIMÕTTED</h2>
              <p>
                Votemo OÜ andmekaitsetingimused ja küpsiste kasutamise põhimõtted
                asuvad <a rel="noopener noreferrer" target="_blank" href="https://www.votemo.eu/privacy-policy">siin</a>.
              </p>
            </div>
          </PerfectScrollBar>
          { (isTermsAccepted() || allowBack) && isModal && (
            <div className="back" style={{ paddingBottom: '0.5rem', paddingTop: '1rem' }}>
              {isModal ? (
                <button className="btn-link" type="button" onClick={() => setShowTerms(false)}>
                  {t('pages.terms.go_back')}
                </button>
              ) : (
                <Link to="/">{t('pages.terms.go_back')}</Link>
              )}
            </div>
          )}
        </div>
      </CPage>
    );
  }
  return (
    <CPage className={pageClassList.join(' ')}>
      <div className="page-inner">
        { (isTermsAccepted() || allowBack) && (
          <div className="back" style={{ paddingBottom: '0.5rem' }}>
            {isModal ? (
              <button className="btn-link" type="button" onClick={() => setShowTerms(false)}>
                {t('pages.terms.go_back')}
              </button>
            ) : (
              <Link to="/">{t('pages.terms.go_back')}</Link>
            )}
          </div>
        )}
        <PerfectScrollBar
          options={{
            suppressScrollX: true,
          }}
        >
          <div className="page__header__terms">
            <h1>{t('pages.terms.title')}</h1>
          </div>
          <div className="page__content__terms">
            {IsCustomClient(showInfo)
              ? (
                <>
                  While engaging on this platform, pretend your grandmother is looking over
                  your shoulder, and conduct yourself with the same respect, kindness, and
                  courtesy that you would in the theater! All responses are monitored, so
                  please refrain from inappropriate or offensive comments.
                </>
              )
              : (
                <>
                  We are thrilled you&apos;re here! This is the place for Votemo&apos;s Terms of Use.
                  If you have any questions, please feel free to contact us
                  at <a href="mailto:support@votemo.eu">support@votemo.eu.</a> You can find
                  our Privacy Policy <a rel="noopener noreferrer" target="_blank" href="https://www.votemo.eu/privacy-policy">here</a>.
                </>
              )}
          </div>
        </PerfectScrollBar>
      </div>
    </CPage>
  );
};

export default TermsPage;
