import React from 'react';
import styled from 'styled-components/macro';

const ResultBase = styled.div`
  font-size: 14px;
  line-height: 1em;
  font-weight: 300;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #c0c0c0;
  padding: 8px 4px;

  span {
    flex-grow: 1;
    padding: 4px;
  }

  strong {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 64px;
    height: 100%;
    padding: 0 8px;
    font-weight: 500;
  }
`;
const TopFiveResult = styled(ResultBase)`
  font-size: 20px;
  font-weight: 500;
`;
const TopThreeResult = styled(ResultBase)`
  font-size: 24px;
  font-weight: 500;
`;
const TopResult = styled(ResultBase)`
  font-size: 32px;
  font-weight: 800;
`;

const renderResult = (Component, { count, children }) => (
  <Component>
    <span>{children}</span>
    <strong>x{count}</strong>
  </Component>
);

export default function WordCloudResult({ index, count, children }) {
  if (index < 1) return renderResult(TopResult, { index, count, children });
  if (index < 3) return renderResult(TopThreeResult, { index, count, children });
  if (index < 5) return renderResult(TopFiveResult, { index, count, children });
  return renderResult(ResultBase, { index, count, children });
}
