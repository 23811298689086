import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationEN from '../locales/en/translation.json';
import translationLV from '../locales/lv/translation.json';
import translationEE from '../locales/et/translation.json';
import translationCA from '../locales/ca/translation.json';

const resources = {
  lv: {
    translation: translationLV,
  },
  en: {
    translation: translationEN,
  },
  et: {
    translation: translationEE,
  },
  ca: {
    translation: translationCA,
  },
};

i18n
// load translation using xhr -> see /public/locales
// learn more: https://github.com/i18next/i18next-xhr-backend
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    lng: 'en', // Default language
    fallbackLng: 'en', // Fallback language if translation not found
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    supportedLngs: ['en', 'et', 'ca', 'lv'],
    react: {
      useSuspense: false,
    },
    resources,
  });

export default i18n;
