import { useEffect, useState } from 'react';

/**
 * @param { function(string):boolean } validators
 * @return *
 */
const useValidatedValue = (...validators) => {
  const [value, setValue] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [hasTyped, setHasTyped] = useState(false);

  useEffect(() => {
    if (hasTyped || value.length === 0) return;

    setHasTyped(true);
  }, [value, hasTyped]);

  useEffect(() => {
    if (value.length === 0) {
      setIsValid(false);
      return;
    }

    const valid = validators.every((validate) => {
      if (typeof validate !== 'function') {
        return true;
      }

      return validate(value);
    });

    setIsValid(valid);
  }, [value, validators]);

  return [setValue, {
    value,
    isValid,
    hasTyped,
  }];
};

export default useValidatedValue;
