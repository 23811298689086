import React from 'react';
import { useTranslation } from 'react-i18next';

const CommentErrorModal = ({ setShowModal, errorTitle, errorMessage }) => {
  const { t } = useTranslation();
  return (
    <div style={{ textAlign: 'center' }}>
      <div className="modal-inner">
        <div className="modal__header">
          <div className="modal__title">
            <div className="modal__icon animated long bounceIn">
              <i className="fas fa-exclamation-circle" />
            </div>
          </div>
        </div>
        <div className="modal__content">
          <h2>
            {errorTitle || t('modules.vote.form.error')}
          </h2>
          <p>
            {errorMessage || t('modules.vote.form.error_detail')}
          </p>
        </div>
        <div className="modal__actions">
          <button type="button" className="actions__confirm" onClick={() => setShowModal(false)}>
            {t('modules.vote.form.error_ok')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CommentErrorModal;
