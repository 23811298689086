import React from 'react';
import PerfectScrollBar from 'react-perfect-scrollbar';
import ModuleHeader from '../../ModuleHeader/ModuleHeader';
import ScrollingHeaderImage from '../../ScrollingHeaderImage';
import VoteTypeText from './VoteTypeText/VoteTypeText';

const VotingScreen = ({ module, totalCount, onUpdate }) => {
  // todo: this is not good practice
  const renderVoteSubtype = () => {
    switch (module.subtype) {
      case 'photo':
        return (
          <VoteTypeText
            isPhoto
            moduleId={module.id}
            options={module.options}
            totalCount={totalCount}
            onUpdate={onUpdate}
          />
        );
      case 'text':
        return (
          <VoteTypeText
            moduleId={module.id}
            options={module.options}
            totalCount={totalCount}
            onUpdate={onUpdate}
          />
        );
      default:
        return <div className="error">Something went wrong</div>;
    }
  };

  return (
    <div id={`module-${module.id}`} className="module module__vote">
      <PerfectScrollBar
        options={{
          wheelSpeed: 0.5,
          swipeEasing: false,
          suppressScrollX: true,
          wheelPropagation: false,
          scrollYMarginOffset: 10,
        }}
      >
        <ScrollingHeaderImage image={module.image} />
        <ModuleHeader module={module} />
        <div style={{ padding: '1rem 1rem 7rem' }}>
          {renderVoteSubtype()}
        </div>
      </PerfectScrollBar>
    </div>
  );
};

export default VotingScreen;
