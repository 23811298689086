import React, { useEffect, useMemo, useState } from 'react';
import { Direction, Range } from 'react-range';
import * as Styled from './MoodmeterSlider.styled';

const useThumbExtra = ({ value, show = false, always = false }) => {
  const [visible, setVisible] = useState(false);

  // set visibility when always visible
  useEffect(() => {
    if (!show || !always) {
      return () => {};
    }

    setVisible(true);
    return () => {
      setVisible(false);
    };
  }, [show, always, setVisible]);

  // set hide timeout if sometimes visible
  useEffect(() => {
    if (!show || always) {
      return () => {};
    }

    const timeout = setTimeout(() => {
      setVisible(false);
    }, 1500);
    setVisible(true);

    return () => {
      setVisible(false);
      clearTimeout(timeout);
    };
  }, [show, always, value, setVisible]);

  return visible;
};
const MoodmeterSlider = ({
  onChange,
  value = 0,
  leftLabels = [],
  rightLabels = [],
  color = '#000000',
  secondaryColor = '#ffffff',
  fontColor = '#000000',
  showValue = false,
  alwaysShowValue = false,
  trackSize = 3,
  thumbSize = 32,
  min = 0,
  max = 100,
}) => {
  const style = useMemo(
    () => ({
      color, secondaryColor, fontColor, trackSize, thumbSize,
    }),
    [color, secondaryColor, fontColor, trackSize, thumbSize],
  );

  const showExtra = useThumbExtra({
    value,
    show: showValue,
    always: alwaysShowValue,
  });

  const clampedValue = useMemo(
    () => Math.max(min, Math.min(max, value)),
    [min, max, value],
  );

  return (
    <Styled.Container {...style}>
      <Styled.Labels {...style} left>
        {leftLabels.map((label) => <span key={label}>{label}</span>)}
      </Styled.Labels>
      <Styled.Wrapper {...style}>
        <Range
          min={0}
          max={100}
          step={1}
          values={[clampedValue]}
          direction={Direction.Up}
          onChange={([val]) => onChange(val)}
          renderTrack={({ props, children }) => (
            <Styled.SliderTrack {...style} {...props}>
              {children}
            </Styled.SliderTrack>
          )}
          renderThumb={({ props }) => (
            <Styled.SliderThumbWrapper
              {...style}
              {...props}
            >
              <Styled.SliderThumbExtra visible={showExtra} {...style}>
                {clampedValue}%
              </Styled.SliderThumbExtra>
              <Styled.SliderThumb {...style} />
            </Styled.SliderThumbWrapper>
          )}
        />
      </Styled.Wrapper>
      <Styled.Labels {...style} right>
        {rightLabels.map((label) => <span key={label}>{label}</span>)}
      </Styled.Labels>
    </Styled.Container>
  );
};
export default MoodmeterSlider;
