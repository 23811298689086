import ReactTextarea from 'react-textarea-autosize';
import styled from 'styled-components/macro';

export const hasErrorContainer = ({ hasError }) => hasError && `
  color: red;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-family: Montserrat, sans-serif;
  
  ${hasErrorContainer}
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const TextareaWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  background-color: white;
`;
export const Textarea = styled(ReactTextarea)`
  min-height: 16px;
  font-family: Montserrat, sans-serif;
  width: 100%;
  padding: 12px 100px 12px 16px;
  border: none;
  font-size: 12px;
  outline: none;
  resize: vertical;
  color: inherit;
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
`;

export const Button = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4px;
  font-size: .75rem;
  padding: .35rem;
  border-radius: .2rem;
  color: #ffffff;
  background-color: #111cf0;
  border: none;
  height: 24px;
  cursor: pointer;

  &:disabled, &[disabled] {
    color: #505151;
    background-color: lightgrey;
  }
`;

export const LengthWrapper = styled.div`
  display: flex;
  font-size: 12px;
  padding: 4px 8px;
`;
