import React from 'react';
import * as Styled from './Sprite.styled';

const Sprite = ({ imageUrl, offset, width, height, steps }) => (
  <Styled.Wrapper offset={offset}>
    <Styled.Sprite
      image={imageUrl}
      width={width}
      height={height}
      steps={steps}
    />
  </Styled.Wrapper>
);
export default Sprite;
