import React from 'react';
import Thread from '../Thread';
import ThreadReply from '../ThreadReply';
import * as Styled from './ThreadList.styled';

const threadKey = (thread) => {
  if (!thread.id) {
    return `${thread.uid}_${thread.createdAt}`;
  }
  return thread.id;
};

// TODO: Backend should promise/lock-in an ID for the comment and return it when creating
const ThreadList = ({
  threads = [],
  upvotes = {},
  enableReplies = false,
  enableUpvotes = false,
  showTotalUpvotes = false,
  onAddUpvote = () => {},
  onRemoveUpvote = () => {},
  onReply = () => {},
  authorColor = '#000000',
  moderatedAuthorColor = '#000000',
  upvoteColor = '#0000ff',
}) => {
  // Optimistic loading
  const totalUpvotes = (thread) => {
    if (thread.has_upvoted === upvotes[thread.id]) {
      return thread.total_upvotes;
    }

    if (upvotes[thread.id]) {
      return thread.total_upvotes + 1;
    }

    return thread.total_upvotes - 1;
  };

  return (
    <Styled.Container>
      {threads.map((thread) => thread && !thread.is_hidden && (
        <Styled.ThreadWrapper key={threadKey(thread)}>
          <Thread
            author={thread.author}
            authorColor={authorColor}
            moderatedAuthorColor={moderatedAuthorColor}
            upvoteColor={upvoteColor}
            isModerated={thread.is_moderated}
            totalReplies={(thread.replies && thread.replies.length) || 0}
            totalUpvotes={totalUpvotes(thread)}
            hasUpvoted={upvotes[thread.id]}
            timestamp={thread.createdAt}
            content={thread.text}
            enableReplies={enableReplies && !!thread.id}
            enableUpvotes={enableUpvotes && !!thread.id}
            showTotalUpvotes={showTotalUpvotes}
            onAddUpvote={() => onAddUpvote(thread)}
            onRemoveUpvote={() => onRemoveUpvote(thread)}
            onReply={() => onReply(thread)}
          />
          {(enableReplies && thread.replies.comments.length > 0)
            && thread.replies.comments.map((reply) => (
              <Styled.ReplyWrapper key={threadKey(reply)}>
                <ThreadReply
                  author={reply.author}
                  authorColor={authorColor}
                  moderatedAuthorColor={moderatedAuthorColor}
                  upvoteColor={upvoteColor}
                  isModerated={reply.is_moderated}
                  totalUpvotes={totalUpvotes(reply)}
                  hasUpvoted={upvotes[reply.id]}
                  timestamp={reply.createdAt}
                  content={reply.text}
                  enableUpvotes={enableUpvotes && !!reply.id}
                  showTotalUpvotes={showTotalUpvotes}
                  onAddUpvote={() => onAddUpvote(reply)}
                  onRemoveUpvote={() => onRemoveUpvote(reply)}
                />
              </Styled.ReplyWrapper>
            ))}
        </Styled.ThreadWrapper>
      ))}
    </Styled.Container>
  );
};
export default ThreadList;
