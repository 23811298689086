import React from 'react';
import { Route } from 'react-router-dom';
import styled from 'styled-components/macro';
import SwipeableRoutes from 'react-swipeable-routes';
import { zIndex, sizes, colors } from '../../library/styleAbstracts/variables';
import useShow from '../../hooks/useShow';
import NavButton from '../NavButton';
import ModuleComponent from '../Module';
import WaterMark from '../Watermark';

const CContent = styled.div`
  height: 100%;
  position: relative;

  & > div {
    height: 100%;
  }

  .react-swipeable-view-container {
    height: 100%;
  }
`;

const CSwipeableRoutes = styled(SwipeableRoutes)`
  height: 100%;
  background-color: ${colors.defaultModuleBackground};
`;

const CNavButtons = styled.div`
  position: absolute;
  z-index: ${zIndex.navButtons};
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  margin: ${sizes.navButtonMargins};
  height: ${sizes.navButtonHeight};
`;

// this determines which component needs to be rendered for each module
const getComponentForModule = (module, showInfo) => (
  // this is needed to get both the module and match objects inside the component
  // the module object is provided by us and the match object comes from swipeable routes
  (props) => <ModuleComponent module={module} showInfo={showInfo} {...props} />
);

const handleRouteChanged = () => {
  if (document.body.classList.contains('scroll-active')) {
    document.body.classList.remove('scroll-active');
  }
};

const Content = () => {
  const {
    modules, previous, next, currentModule, showInfo,
  } = useShow();

  // this is a safety measure, to make sure we don't start rendering stuff when there are no modules yet
  if (!modules) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <CContent>
        <div>
          {modules.length > 1 && (
            <CNavButtons>
              <NavButton to={previous} disabled={currentModule.id === previous} />
              <NavButton next to={next} disabled={currentModule.id === next} />
            </CNavButtons>
          )}
          <CSwipeableRoutes onChangeIndex={handleRouteChanged}>
            {modules.map((module) => (
              <Route
                path={`/${module.id}`}
                key={module.id}
                component={getComponentForModule(module, showInfo)}
                defaultParams={module}
              />
            ))}
          </CSwipeableRoutes>
        </div>
      </CContent>
      <WaterMark />
    </>
  );
};

export default Content;
