export const ModuleType = {
  Comments: 'comment',
  QuestionsAndAnswers: 'questions_answers',
  Vote: 'vote',
  Reaction: 'reaction',
  ProductLaunch: 'product_launch',
  WordCloud: 'word_cloud',
  Moodmeter: 'moodmeter',
};
export const ModuleStatus = {
  Live: 'LIVE',
  NotStarted: 'NOT_STARTED',
  Ended: 'ENDED',
};
