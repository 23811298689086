import React from 'react';
import useActiveState from '../../hooks/active-state';
import useValidatedValue from '../../hooks/validated-value';
import wrapSetStateEvent from '../../utils/event-set-state';
import { noop, noopWithValue } from '../../utils/noop';

import {
  AuthorInput,
  AuthorLabelText,
  AuthorLengthContainer,
  AuthorWrapper,
} from './AuthorInput.styled';

const defaultProps = {
  maxLength: 35,
  required: false,
  disabled: true,
  onChange: noop,
  validate: noopWithValue(true),
  labels: {
    author: '',
    authorError: '',
  },
};

const AuthorInputComponent = (props = defaultProps) => {
  const { options, labels, onChange } = props;
  const [setValue, { value, isValid, hasTyped }] = useValidatedValue(options.validate);
  const [active, activeState] = useActiveState(value.length > 0);

  if (options.disabled === true) return null;

  return (
    <AuthorWrapper hasError={!isValid && hasTyped}>
      <div>
        <AuthorLabelText
          isActive={active}
          hasError={!isValid && hasTyped}
        >
          {isValid ? labels.author : labels.authorError}
        </AuthorLabelText>
        <AuthorInput
          id="comment-author"
          maxLength={options.maxLength}
          onChange={wrapSetStateEvent(setValue, onChange)}
          onFocus={activeState.focus}
          onBlur={activeState.blur}
          value={value}
        />
        <AuthorLengthContainer>
          <span>{value.length}</span>&nbsp;/&nbsp;<span>{options.maxLength}</span>
        </AuthorLengthContainer>
      </div>
    </AuthorWrapper>
  );
};

AuthorInputComponent.defaultProps = defaultProps;
export default AuthorInputComponent;
