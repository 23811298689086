import { useMemo, useRef } from 'react';
import useScrollDetector from '../../hooks/useScrollDetector';
import ScrollingHeaderImage from './ScrollingHeaderImage';

const useScrollingHeaderImage = (image) => {
  const ref = useRef();
  const [scrollDetector, { top: scrollTop }] = useScrollDetector(ref);
  const props = useMemo(
    () => ({ image, scrollTop }),
    [image, scrollTop],
  );
  return [
    ScrollingHeaderImage,
    { props, ref, scrollDetector },
  ];
};

export default useScrollingHeaderImage;
