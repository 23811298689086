import React, { useCallback, useContext, useState } from 'react';

// eslint-disable-next-line
const noopSetState = (oldState) => {};

/**
 * @type {React.Context<[{},function(object):object]>}
 */
export const UpvoteContext = React.createContext([{}, noopSetState]);

export const useUpvoteContext = () => useContext(UpvoteContext);

export default function UpvoteContextProvider(props) {
  const { children } = props;
  const [upvotes, setUpvotes] = useState({});
  const setUpvote = useCallback((threadId, upvoted) => {
    setUpvotes((prev) => ({ ...prev, [threadId]: upvoted }));
  }, [setUpvotes]);

  return (
    <UpvoteContext.Provider value={[upvotes, setUpvote]}>
      {children}
    </UpvoteContext.Provider>
  );
}
