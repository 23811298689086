import React from 'react';
import * as Styled from './ModuleHeader.styled';

const ModuleSubtitle = ({ module }) => {
  switch (module.status) {
    case 'LIVE':
      return <Styled.HeadingSubtitle>{module.subtitle}</Styled.HeadingSubtitle>;
    case 'NOT_STARTED':
      return <Styled.HeadingSubtitle>{module.subtitle_not_started}</Styled.HeadingSubtitle>;
    case 'ENDED':
      return <Styled.HeadingSubtitle>{module.subtitle_ended}</Styled.HeadingSubtitle>;
    default:
      return null;
  }
};

const ModuleHeader = ({ module }) => {
  if (!module.title && !module.subtitle) return null;

  return (
    <Styled.Heading>
      <Styled.HeadingTitle>{module.title}</Styled.HeadingTitle>
      <ModuleSubtitle module={module} />
    </Styled.Heading>
  );
};

export default ModuleHeader;
