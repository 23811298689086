import React, { useMemo } from 'react';
import AuthoredInputField from '../../../components/AuthoredInputField';

const AddComment = ({
  author = '',
  value = '',
  showAuthor = false,
  inputRef = undefined,
  onSubmit = () => {},
  onChange = () => {},
  onChangeAuthor = () => {},
  authorLabel = 'Author',
  submitLabel = 'Submit',
  placeholder = '',
}) => {
  const submitDisabled = useMemo(
    () => !value,
    [value],
  );

  const handleSubmit = () => {
    onSubmit({ value, author });
  };

  return (
    <AuthoredInputField
      inputRef={inputRef}
      author={author}
      value={value}
      onChange={onChange}
      onSubmit={handleSubmit}
      onChangeAuthor={onChangeAuthor}
      maxLength={200}
      authorLabel={authorLabel}
      placeholder={placeholder}
      showAuthor={showAuthor}
      showLength
      submitDisabled={submitDisabled}
      submitLabel={submitLabel}
    />
  );
};

export default AddComment;
