import { useEffect, useMemo, useState } from 'react';
import useShow from './useShow';

/**
 * Parses the sides and returns a viewport
 * @param { number } sides The offsets from each side (top-right-bottom-left)
 * @return {{top: number, left: number, bottom: number, right: number}}
 */
const viewport = (...sides) => {
  switch (sides.length) {
    case 0:
      return {
        top: 0, right: 0, bottom: 0, left: 0,
      };
    case 1:
      return {
        top: sides[0], right: sides[0], bottom: sides[0], left: sides[0],
      };
    case 2:
      return {
        top: sides[0], right: sides[1], bottom: sides[0], left: sides[1],
      };
    case 3:
      return {
        top: sides[0], right: sides[1], bottom: sides[2], left: sides[1],
      };
    default:
      return {
        top: sides[0], right: sides[1], bottom: sides[2], left: sides[3],
      };
  }
};

const getDimensions = (element = document.documentElement) => {
  const { clientWidth: width, clientHeight: height } = element;
  return { element, width, height };
};

const useViewportSize = () => {
  const root = document.getElementById('root');
  const [size, setSize] = useState(getDimensions(root));

  useEffect(() => {
    const handleUpdate = () => {
      const { width, height } = getDimensions(root);
      if (size.width !== width || size.height !== height) {
        setSize({ width, height });
      }
    };
    const interval = setInterval(handleUpdate, 500);
    root.addEventListener('resize', handleUpdate);

    return () => {
      clearInterval(interval);
      root.removeEventListener('resize', handleUpdate);
    };
  }, [size]);

  return size;
};

// TODO: The header and footer height should not be hard-coded here
const useViewport = () => {
  const { showHeader, modules = [] } = useShow();
  const { width, height } = useViewportSize();
  const footerVisible = useMemo(
    () => (modules.length > 1),
    [modules],
  );
  const headerOffset = useMemo(
    () => (showHeader ? 56 : 0),
    [showHeader],
  );
  const footerOffset = useMemo(
    () => (footerVisible ? 20 : 0),
    [footerVisible],
  );

  return {
    ...viewport(headerOffset, 16, footerOffset),
    width,
    height,
    footerVisible,
  };
};

export default useViewport;
