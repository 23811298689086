import { gql } from '@apollo/client';

const SHOW_UPDATE = gql`
    query ($domain_name: String!) {
        showByDomain(domain_name: $domain_name) {
            updated_at
        }
    }

`;

export default SHOW_UPDATE;
