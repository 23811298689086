import { gql } from '@apollo/client';

const VOTES = gql`
    query ($module_id: Int!) {
        votes(question_id: $module_id) {
            results {
                totalWeight,
                totalCount,
                options {
                    id,
                    totalWeight
                    totalCount
                }
            }
        }
    }
`;

const VOTES_WITH_OPTIONS = gql`
    query ($module_id: Int!) {
        votes(question_id: $module_id) {
            results {
                totalWeight,
                totalCount,
                options {
                    id
                    totalWeight
                    totalCount
                    option {
                        title
                        subtitle
                    }
                }
            }
        }
    }
`;

export default VOTES;
export { VOTES_WITH_OPTIONS };
