import React from 'react';
import Textarea from 'react-textarea-autosize';
import useActiveState from '../../hooks/active-state';
import useValidatedValue from '../../hooks/validated-value';
import useViewport from '../../hooks/viewport';
import wrapSetStateEvent from '../../utils/event-set-state';
import { noop } from '../../utils/noop';
import AuthorInput from './AuthorInput';
import {
  BottomInputButton,
  BottomInputButtonWrapper,
  BottomInputContainer, BottomInputLengthContainer,
  BottomInputTextareaWrapper,
  BottomInputWrapper,
} from './BottomInput.styled';

const defaultProps = {
  // Options
  options: {
    maxLength: 200,
    disabled: false,
    validate: () => true,
  },
  // Author options
  authorOptions: AuthorInput.defaultProps,
  // Reply options
  replyOptions: {
    disabled: true,
    thread: null,
    author: null,
  },
  // Labels
  labels: {
    placeholder: '',
    placeholderError: '',
    author: '',
    authorError: '',
    replying: '',
    send: '',
  },
  // Event handlers
  onSubmit: noop,
  onCloseReply: noop,
  onChange: noop,
  onChangeAuthor: noop,
};

const BottomInput = (props = defaultProps) => {
  const viewport = useViewport();

  const { options, labels } = props;
  const { authorOptions, replyOptions } = props;
  const { onSubmit, onCloseReply } = props;
  const { onChange, onChangeAuthor } = props;

  const [, activeState] = useActiveState();
  const [setValue, { value, isValid, hasTyped }] = useValidatedValue(
    authorOptions.validate,
    options.validate,
  );

  const handleSubmit = async () => {
    if (!isValid) {
      return;
    }

    const submitFailed = await onSubmit(value) === false;
    if (!submitFailed) {
      setValue('');
    }
  };

  return (
    <BottomInputWrapper viewport={viewport}>
      {replyOptions.disabled !== true && (
        <div className="comment-reply-form-container">
          <button type="button" className="btn btn-link close-reply" onClick={onCloseReply}>
            <span className="close-reply__description">
              <span className="description">{labels.replying}</span>
              <span className="author">{replyOptions.author}</span>
            </span>
            <i className="fas fa-times" />
          </button>
        </div>
      )}
      <AuthorInput
        onChange={onChangeAuthor}
        options={authorOptions}
        labels={labels}
      />
      <BottomInputContainer hasError={!isValid && hasTyped}>
        <BottomInputTextareaWrapper>
          <Textarea
            placeholder={isValid ? labels.placeholder : labels.placeholderError}
            onChange={wrapSetStateEvent(setValue, onChange)}
            maxLength={options.maxLength}
            onFocus={activeState.focus}
            onBlur={activeState.blur}
            value={value}
            style={{ height: 38 }}
          />
        </BottomInputTextareaWrapper>
        <BottomInputButtonWrapper>
          <BottomInputButton
            type="button"
            onClick={handleSubmit}
            className={value.length < 1 ? 'disabled' : undefined}
            disabled={options.disabled || value.length < 1}
          >
            {value.length > 0 && !options.disabled && labels.send}
            <i className="fas fa-paper-plane" />
          </BottomInputButton>
        </BottomInputButtonWrapper>
      </BottomInputContainer>
      <BottomInputLengthContainer>
        <span>{value.length}</span>&nbsp;/&nbsp;<span>{options.maxLength}</span>
      </BottomInputLengthContainer>
    </BottomInputWrapper>
  );
};

BottomInput.defaultProps = defaultProps;
export default BottomInput;
