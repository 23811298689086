import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 1rem;
`;
export const Icon = styled.img`
  object-fit: contain;
  width: 4rem;
  height: 4rem;
`;
const titleColor = ({ color }) => color;
export const Title = styled.h1`
  font-size: 2.6rem;
  text-align: center;
  margin: 2rem 0;
  color: ${titleColor};
`;

const buttonColor = ({ primary, color }) => {
  if (!primary) return color;
  return 'white';
};
const buttonBackgroundColor = ({ primary, color }) => {
  if (!primary) return 'white';
  return color;
};
const buttonBorderColor = ({ color }) => color;
export const Button = styled.button`
  width: 100%;
  color: ${buttonColor};
  background-color: ${buttonBackgroundColor};
  border: 2px solid ${buttonBorderColor};
  font-size: 1.6rem;
  padding: .5em;
  border-radius: 5px;
  margin: .5rem 0;
  cursor: pointer;
`;
